import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { JobActions } from '@t12/store/job/actions/job.actions';
import { TabJob } from '../../enums/tab-job.enum';
import { JobManagerService } from '../job-manager/job-manager.service';

@Injectable({
  providedIn: 'root',
})
export class WorkshopManagerService {
  constructor(
    private readonly _jobService: JobManagerService,
    private readonly _store: Store,
  ) {}

  public initWorkshop(workshop: Workshop): void {
    if (!this._jobService.canInteractEntityJob(workshop.jobCode)) return;

    this._store.dispatch(HudDisplayActions.showHud({ name: 'jobs' }));
    this._store.dispatch(JobActions.setWorkshop({ workshop }));
    this._store.dispatch(JobActions.setActiveTab({ tab: TabJob.Recipes }));
  }

  public closeWorkshop(): void {
    this._store.dispatch(JobActions.setWorkshop({ workshop: null }));
    this._store.dispatch(HudDisplayActions.hideHud({ name: 'jobs' }));
    this._store.dispatch(JobActions.setActiveTab({ tab: TabJob.Jobs }));
    this._jobService.removeActiveJob();
  }
}
