import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { ContainerActions } from '@t12/store/container/actions/container.actions';
import {
  getPositionContainer,
  getContainerItems,
  getCurrentPageContainer,
  getContainerKind,
} from '@t12/store/container/selectors/container.selectors';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { nbItemsPageContainer } from '../../constants/max-items-page-container.constant';

@Injectable({
  providedIn: 'root',
})
export class ContainerUtilsService {
  constructor(
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
    private readonly _worldService: WorldGeneratorService,
  ) {}

  public setDisplayedContainerItems() {
    const itemsContainer = this._utilsService.getSelect(getContainerItems);
    const currentPage =
      this._utilsService.getSelect(getCurrentPageContainer) - 1;
    const items = itemsContainer.slice(
      currentPage * nbItemsPageContainer,
      (currentPage + 1) * nbItemsPageContainer,
    );

    this._store.dispatch(ContainerActions.setItemsDisplayed({ items }));
  }

  public closeContainer() {
    const containerKind = this._utilsService.getSelect(getContainerKind);

    this._store.dispatch(HudDisplayActions.hideHud({ name: 'container' }));
    this._removeContainerEntityFromTile(containerKind);
    this._store.dispatch(ContainerActions.init());
    this._store.dispatch(ContainerActions.setCurrentPage({ page: 0 }));
    this._store.dispatch(ContainerActions.setMaxPage({ maxPage: 1 }));
  }

  private _removeContainerEntityFromTile(containerKind: ContainerKind) {
    const position = this._utilsService.getSelect(getPositionContainer);

    switch (containerKind) {
      case ContainerKind.Loot: {
        this._worldService.removeEntity('container', position.y, position.x);
        return;
      }
      case ContainerKind.Harvest: {
        this._worldService.removeEntity('harvestPoint', position.y, position.x);
        return;
      }
    }
  }
}
