import { Injectable } from '@angular/core';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { attackLabel } from '../../constants/attack-label.constant';
import { hitLabel } from '../../constants/hit-label.constant';
import { AttackKind } from '../../types/attack-kind.type';
import { HitKind } from '../../types/hit-kind.type';

@Injectable({
  providedIn: 'root',
})
export class FightHitService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _chatService: ChatManagerService,
  ) {}

  // Argument : Attaquant, Cible à toucher
  // Résultat : Retourne le type de l'attaque réussie
  public tryToHit(
    attacker: Character,
    target: Character,
    attack: AttackKind,
  ): HitKind {
    const { lvl: attackerLvl, stats: attackerStats } = attacker;
    const {
      lvl: targetLvl,
      stats: targetStats,
      equipments: targetEquipments,
    } = target;
    const { dex: attackerDex, sag: attackerSag } = attackerStats;
    const { dex: targetDex, sag: targetSag } = targetStats;
    const isBehind = attacker.looking === target.looking;
    const haveWeapon = !!(
      targetEquipments?.[5]?.code ?? (target as NPC | Monster).hasWeapon
    );
    const haveShield = !!(
      targetEquipments?.[6]?.code ?? (target as NPC | Monster).hasShield
    );
    const canParry = !!(attack === 'physic' && haveWeapon && !isBehind);
    const canBlock = !!(attack === 'physic' && haveShield && !isBehind);
    let coefAvoid: number;
    let coefCrit: number;

    if (attack === 'physic') {
      coefAvoid = Math.floor(
        16 * ((targetDex * 1.2) / attackerDex) + targetLvl / attackerLvl,
      );
      coefCrit = isBehind
        ? Math.floor((attackerDex / targetDex) * 4 + attackerDex / targetDex)
        : Math.floor((attackerDex / targetDex) * 1.5);
    } else if (attack === 'magic') {
      coefAvoid = Math.floor(
        9 * (targetSag / attackerSag) + targetLvl / attackerLvl,
      );
      coefCrit = Math.floor(attackerSag / targetSag);
    }
    coefAvoid = Math.min(Math.max(coefAvoid, 1), 75);
    coefCrit = Math.min(Math.max(coefCrit, 1), 10);

    if (haveShield) {
      coefAvoid = Math.floor(coefAvoid / 2);
    }
    return this._getKindHit(coefAvoid, coefCrit, canParry, canBlock, !isBehind);
  }

  // Argument : Dégâts et réduction, attaquant, cible, type de coup, type d'attaque
  // Résultat : Retourne les dégâts totaux et ceux potentiellement réduits
  public displayChatLogHit(
    attacker: Character,
    target: Character,
    dmgAndReduce: number[],
    attack: AttackKind,
    hit: HitKind,
  ): void {
    const { name: attackerName, looking: attackerLooking } = attacker;
    const {
      isPlayer: targetIsPlayer,
      name: targetName,
      looking: targetLooking,
    } = target;
    const typeMe = !targetIsPlayer ? ChatLogKind.Bonus : ChatLogKind.Malus;
    const typeMeOpposite = targetIsPlayer
      ? ChatLogKind.Bonus
      : ChatLogKind.Malus;
    const labelAttack = attackLabel[attack];
    const dmg = dmgAndReduce[0];
    const labelDmg = `a infligé ${dmg} dégâts (${labelAttack}) à ${targetName}`;
    const isBehind =
      attackerLooking === targetLooking && attackerLooking !== undefined;
    const reduce =
      dmgAndReduce[1] > 0 ? `+${dmgAndReduce[1]}` : dmgAndReduce[1];

    if (hit === 'block' || hit === 'parry' || hit === 'crit') {
      this._chatService.addChatLog(
        'fight',
        attackerName,
        `${labelDmg} (${hitLabel[hit]}: ${reduce} dégâts)`,
        typeMe,
      );
    } else if (attack === 'physic' && hit === 'hit' && isBehind) {
      this._chatService.addChatLog(
        'fight',
        attackerName,
        `${labelDmg} (Backstab)`,
        typeMe,
      );
    } else if (hit === 'avoid') {
      this._chatService.addChatLog(
        'fight',
        targetName,
        `a esquivé le coup de ${attackerName}`,
        typeMeOpposite,
      );
    } else if (hit === 'miss') {
      this._chatService.addChatLog(
        'fight',
        attackerName,
        `a raté ${targetName}`,
        typeMeOpposite,
      );
    } else {
      this._chatService.addChatLog('fight', attackerName, labelDmg, typeMe);
    }
  }

  // Argument : Type de coup
  // Résultat : Renvoie le chemin d'accès au fichier son correspondant au type de coup
  public soundHit(hit: string): void {
    if (hit === 'block') {
      const rand = Math.floor(Math.random() * 4);
      this._audioService.playSound('impacts', `block_${rand}`);
    } else if (hit === 'parry') {
      const rand = Math.floor(Math.random() * 5);
      this._audioService.playSound('impacts', `parry_${rand}`);
    } else if (hit === 'crit') {
      const rand = Math.floor(Math.random() * 3);
      this._audioService.playSound('impacts', `crit_${rand}`);
    }
  }

  // Argument : Coefficient d'esquive, % de critique, peut parer, peut bloquer, est dans le dos
  // Résultat : Retourne le type de l'attaque selon tous les paramètres
  private _getKindHit(
    coefAvoid: number,
    coefCrit: number,
    canParry: boolean,
    canBlock: boolean,
    canAvoid: boolean,
  ): HitKind {
    const avoid: number = coefAvoid > 1 ? coefAvoid : 1;
    const crit: number = coefCrit > 1 ? coefCrit : 1;
    const hit: number = Math.floor(Math.random() * 100);
    let typeHit: HitKind = 'hit';
    if (hit > 97 - crit) {
      typeHit = 'crit';
    } else if (hit > 19 + avoid && hit < 49 + avoid && canBlock) {
      typeHit = 'block';
    } else if (hit > 9 + avoid && hit <= 19 + avoid && canParry) {
      typeHit = 'parry';
    } else if (hit > 5 && hit <= 9 + avoid && canAvoid) {
      typeHit = 'avoid';
    } else if (hit <= 5) {
      typeHit = 'miss';
    }
    return typeHit;
  }
}
