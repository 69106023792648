import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { ContainerMaterial } from '@t12/common/container/enums/container-material.enum';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { PopOverManagerService } from '@t12/hud/services/pop-over/pop-over-manager.service';
import { InventoryPickService } from '@t12/inventory/services/inventory-pick/inventory-pick.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ContainerState } from '@t12/store/container';
import { ContainerActions } from '@t12/store/container/actions/container.actions';
import { getContainer } from '@t12/store/container/selectors/container.selectors';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { take } from 'rxjs';
import { nbItemsPageContainer } from '../../constants/max-items-page-container.constant';
import { ContainerDbService } from '../container-db/container-db.service';
import { ContainerPickService } from '../container-pick/container-pick.service';
import { ContainerUtilsService } from '../container-utils/container-utils.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerManagerService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _containerDbService: ContainerDbService,
    private readonly _containerPickService: ContainerPickService,
    private readonly _containerUtilsService: ContainerUtilsService,
    private readonly _inventoryPickService: InventoryPickService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
    private readonly _worldService: WorldGeneratorService,
  ) {}

  // Argument : Tuile contenant le conteneur
  // Résultat : Ajoute les loots dans le conteneur et met à jour la pagination
  public initContainerHud(container: IContainer): void {
    const openSong = this.getOpeningSoundContainer(container.material);
    this._audioService.playSound('miscs', openSong, 'ogg');
    const containerState: ContainerState = {
      ...container,
      maxPage: Math.ceil([...container.items].length / nbItemsPageContainer),
    };
    this._store.dispatch(
      ContainerActions.setContainer({ container: containerState }),
    );
    this._store.dispatch(HudDisplayActions.showHud({ name: 'container' }));
    this._containerUtilsService.setDisplayedContainerItems();
  }

  public getContainer(containerKind: ContainerKind) {
    this._containerDbService
      .getContainer(containerKind)
      .pipe(take(1))
      .subscribe((container: IContainer) => {
        this.initContainerHud(container);
      });
  }

  getOpeningSoundContainer(containerMaterial: ContainerMaterial): string {
    switch (containerMaterial) {
      case ContainerMaterial.HardWood:
        return 'chest_open_0';
    }
  }

  // Argument : Nom du conteneur, img du conteneur, liste d'objets du coffre
  // Résultat : Crée un coffre correspondant aux paramètres passés
  // Amélioration : Changez le skin du coffre selon la rareté, add item.rank ?
  public placeContainerOnTile(position: Position, container: IContainer): void {
    const tile = this._worldService.getTile(position.y, position.x);
    tile.container = container;
    const { kind, img } = container;
    tile.container = { img, kind } as IContainer;
  }

  // Argument : Objet à ramasser et son index
  // Résultat : Permet de récupérer les objets du conteneur
  public pickContainerItem(index: number): void {
    const container = this._utilsService.getSelect(getContainer);
    const item = container.displayedItems[index];
    const indexFreeSlotInventory = this._inventoryPickService.canPickItem(item);

    if (indexFreeSlotInventory !== -1) {
      this._popoverService.deletePopOver();
      this._audioService.playSound('miscs', 'drop_leather', 'ogg');
      this._containerPickService.pickItem(item.code);

      const container = this._utilsService.getSelect(getContainer);
      if (container.items.length === 0)
        this._containerUtilsService.closeContainer();
    }
  }
}
