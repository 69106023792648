import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { QuestGoal } from '@t12/common/quest/interfaces/quest-goal.inferface';

export const QUEST_FEATURE_KEY = 'quest';

export interface QuestState {
  quests: QuestInfos[];
  activeGoal?: QuestGoal;
}
export const initialQuestState: QuestState = {
  quests: [],
  activeGoal: null,
};
