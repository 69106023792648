import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';

export const ShopActions = createActionGroup({
  source: 'SHOP',
  events: {
    Init: emptyProps(),
    'Set shop': props<{ shop: Shop }>(),
  },
});
