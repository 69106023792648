import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { KeyHandlerService } from './key-commands/services/key-handler.service';
import { AuthenticationService } from './login/services/auth/authentication.service';
import { SettingsComponent } from './settings/components/settings/settings.component';
import { getHudSettings } from './store/hud-display/selectors/hud-display.selectors';
import { UserActions } from './store/user/actions/user.actions';
import { RouterOutlet } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NgIf, RouterOutlet, AsyncPipe, SettingsComponent],
})
export class AppComponent implements OnInit {
  public readonly title = 'La tour des 12';
  public isVisibleSettings: Observable<boolean> =
    this._store.select(getHudSettings);

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _keyHandler: KeyHandlerService,
    private readonly _store: Store,
  ) {}

  ngOnInit(): void {
    const userDetails = this._authService.getUserDetails();
    if (userDetails) {
      this._store.dispatch(UserActions.loadUser({ user: userDetails }));
    }
  }
}
