import {
  NgClass,
  NgIf,
  NgForOf,
  NgOptimizedImage,
  NgStyle,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  TranslocoDirective,
  provideTranslocoScope,
  TranslocoService,
} from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { Language } from '@t12/common/user/types/language.type';
import { TokenPayload } from '@t12/login/interfaces/userdetails';

import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { LANGUAGES } from '@t12/settings/constants/language.constant';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { getHudSettings } from '@t12/store/hud-display/selectors/hud-display.selectors';
import { UserSettings } from '@t12/store/user';
import { UserActions } from '@t12/store/user/actions/user.actions';

import {
  getUser,
  getUserMail,
  getUserLanguage,
} from '@t12/store/user/selectors/user.selectors';
import { ButtonComponent } from '@t12/ui/components/button/button.component';
import { CornersHalfComponent } from '@t12/ui/components/corners-half/corners-half.component';
import { LanguageSelectComponent } from '@t12/ui/components/language-select/language-select.component';
import { UnSubscriberComponent } from '@t12/utils/component/unsubscriber.component';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { catchError, EMPTY } from 'rxjs';
import { UpdateUserDto } from '../../../../../../back/src/user/dto/update-user.dto';
import { AudioManagerService } from '../../services/audio/audio-manager.service';
import { FullscreenManagerService } from '../../services/fullscreen/fullscreen-manager.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
  standalone: true,
  imports: [
    NgIf,
    CornersHalfComponent,
    FormsModule,
    NgClass,
    ButtonComponent,
    TranslocoDirective,
    NgForOf,
    NgOptimizedImage,
    NgStyle,
    LanguageSelectComponent,
  ],
  providers: [provideTranslocoScope('settings')],
})
export class SettingsComponent extends UnSubscriberComponent implements OnInit {
  public document: Document;
  public stateFullscreen = false;
  public isVisible = false;
  public errorMail = false;
  public errorPw = false;
  public updateMail = false;
  public updatePassword = false;
  public checkDelete = false;
  public labelMail = 'change-mail.label';
  public labelPassword = 'change-password.label';
  public settings: UserSettings = {
    music: false,
    noises: false,
  };
  public LANGUAGES = LANGUAGES;
  public activeLang = this._utilsService.getSelect(getUserLanguage);
  public credentials: TokenPayload = {
    email: undefined,
    password: undefined,
  };

  private readonly _emailPattern: RegExp =
    /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  constructor(
    public readonly fullscreenManagerService: FullscreenManagerService,
    private readonly _audioService: AudioManagerService,
    private readonly _authService: AuthenticationService,
    private readonly _store: Store,
    private readonly _translocoService: TranslocoService,
    private readonly _utilsService: UtilsService,
    private readonly _worldService: WorldGeneratorService,
  ) {
    super();
    this.document = document;

    document.addEventListener('fullscreenchange', () => {
      this.stateFullscreen = !!fullscreenManagerService.fullscreenElement;
    });
  }

  ngOnInit(): void {
    this._store
      .select(getUser)
      .pipe(this.takeUntilDestroyed())
      .subscribe((user) => {
        if (!user) {
          return;
        }
        this.credentials.email = user.email;
        this.settings = JSON.parse(JSON.stringify(user.settings));
      });

    this._store
      .select(getUserLanguage)
      .pipe(this.takeUntilDestroyed())
      .subscribe((lang) => {
        this.activeLang = lang;
      });

    this._store
      .select(getHudSettings)
      .pipe(this.takeUntilDestroyed())
      .subscribe((isVisible: boolean) => {
        this.isVisible = isVisible;
        if (!isVisible) {
          this.credentials.password = '';
          this.resetCheck('mail');
          this.resetCheck('pw');
        }
      });
  }

  // Arguments : Formulaire à reset
  // Résultat : Permet de réinitialiser le statut d'un champs de formulaire invalide / vide
  public resetCheck(value): void {
    if (value === 'mail') {
      this.errorMail = false;
      this.labelMail = 'change-mail.label';
      this.updateMail = false;
    } else {
      this.errorPw = false;
      this.labelPassword = 'change-password.label';
      this.updatePassword = false;
    }
  }

  // Arguments : ------
  // Résultat : Permet de se déconnecter en désactivant le plein écran et la musique
  public logout(): void {
    this.resetCheck('mail');
    this.resetCheck('pw');
    this._store.dispatch(HudDisplayActions.hideHud({ name: 'settings' }));
    this.fullscreenManagerService.removeFullScreen();
    this._audioService.stopBackgroundMusic();
    this._authService.logout();
  }

  // Arguments : ------
  // Résultat : Permet de supprimer le compte actif et se déconnecter ensuite
  public deleteAccount(): void {
    if (this.checkDelete) {
      this._audioService.playSound('interfaces/buttons', 'correct');
      this._authService.deleteAccount();
      this.logout();
    } else {
      this._audioService.playSound('interfaces/buttons', 'error');
    }
  }

  // Arguments : ------
  // Résultat : Permet de mettre à jour les informations du compte.
  // TODO Check if email is available, improve credentials generation => avoid send null or ''
  public updateAccountInfo(): void {
    this._checkFields();
    const { email, password } = this.credentials;
    const canSubmitUpdate = !this.errorMail || !this.errorPw;

    if (canSubmitUpdate) {
      const updateUserDto: UpdateUserDto = {
        email:
          email !== this._utilsService.getSelect(getUserMail)
            ? email
            : undefined,
        password: password || undefined,
      };

      this._authService
        .updateUserDetails$(updateUserDto)
        .pipe(
          this.takeUntilDestroyed(),
          catchError(() => {
            this.labelMail = 'change-mail.error';
            this.errorMail = true;
            this._audioService.playSound('interfaces/buttons', 'error');

            return EMPTY;
          }),
        )
        .subscribe(() => {
          if (updateUserDto.email) {
            this._store.dispatch(
              UserActions.changeMail({ email: this.credentials.email }),
            );
            this.labelMail = 'change-mail.success';
            this.updateMail = true;
          }
          if (updateUserDto.password) {
            this.labelPassword = 'change-password.success';
            this.updatePassword = true;
            this.credentials.password = '';
          }
          this._audioService.playSound('interfaces/buttons', 'correct');
        });
    }
  }

  // Arguments : ------
  // Résultat : Permet d'activer ou non la musique
  public switchMusic(): void {
    this._store.dispatch(
      UserActions.changeMusicState({ music: !this.settings.music }),
    );
    this._authService
      .updateUserDetails$({
        music: this.settings.music,
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe();

    if (this.settings.music) {
      this._audioService.playBackgroundMusic(
        this._worldService.getWorld$().value?.music || 'home',
      );
    } else {
      this._audioService.stopBackgroundMusic();
    }
  }

  // Arguments : ------
  // Résultat : Permet d'activer ou non les sons
  public switchNoises(): void {
    this._store.dispatch(
      UserActions.changeNoisesState({ noises: !this.settings.noises }),
    );
    this._authService
      .updateUserDetails$({
        noises: this.settings.noises,
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe();
  }

  onLangChange(language: Language): void {
    this._translocoService.setActiveLang(language);
    this._store.dispatch(UserActions.changeLanguage({ language }));
    this._authService
      .updateUserDetails$({ language })
      .pipe(this.takeUntilDestroyed())
      .subscribe();
  }

  // Arguments : ------
  // Résultat : Permet de vérifier la validité des informations saisies
  private _checkFields(): void {
    const { email, password } = this.credentials;
    if (email !== '' && !this._emailPattern.test(email)) {
      this.errorMail = true;
      this.labelMail = 'change-mail.invalid';
    } else {
      this.errorMail = false;
    }

    if (
      password &&
      password.length !== 0 &&
      (password.length < 5 || password.length > 22)
    ) {
      this.errorPw = true;
      this.labelPassword = 'change-password.error';
    } else {
      this.errorPw = false;
    }
  }
}
