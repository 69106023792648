import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { DivinityDetails } from '@t12/common/creation-player/interfaces/divinity-details.interface';
import { PersonalityDetails } from '@t12/common/creation-player/interfaces/personality-details.interface';
import { IPlayerCreateDto } from '@t12/common/creation-player/interfaces/player-create-dto.interface';
import { RaceDetails } from '@t12/common/creation-player/interfaces/race-details.interface';
import { StatsDetails } from '@t12/common/creation-player/interfaces/stats-details.interface';
import { RandomNamesCharacter } from '@t12/creation-player/interfaces/random-names-character.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CharacterCreationManagerService {
  private _editMode = false;

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public formatName(name: string): string {
    const words = name.trim().replace(/\s+$/, '').split(' ');

    const capitalizedWords = words.map((word) => {
      if (word.length === 0) return '';

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join(' ');
  }

  // Arguments : ------
  // Résultat : Active le mode édition pour supprimer les personnages
  public switchEdit(): void {
    this._editMode = !this._editMode;
  }

  // Arguments : True ou False
  // Résultat : Change la valeur du mode d'édition selon celle passée
  public setEditMode(value: boolean): void {
    this._editMode = value;
  }

  // Arguments : ------
  // Résultat : Renvoie la valeur du mode d'édition de personnage
  public getEditModeState(): boolean {
    return this._editMode;
  }

  // TODO Décaler toutes les méthodes db dans un service creation-db
  // Arguments : Id du compte
  // Résultat : Renvoie tous les personnages lié au compte
  public getPlayerCharacters$(): Observable<Player[]> {
    return this._http.get(environment(`player`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<Player[]>;
  }

  // Arguments : ------
  // Résultat : Renvoie le nombre maximal de personage autorisé pour cet user
  public getMaxPlayerCharacters$(): Observable<number> {
    return this._http.get(environment(`user/maxCharacter`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<number>;
  }

  // Arguments : Un personnage
  // Résultat : Ajoute un personnage au compte connecté dans la base de données
  public addPlayerCharacter(player: IPlayerCreateDto): Observable<any> {
    return this._http
      .post(environment('creation/player').uri, player, {
        headers: { authorization: `Bearer ${this._authService.getToken()}` },
      })
      .pipe(take(1));
  }

  // Arguments : Id du personnage
  // Résultat : Supprime le personnage précisé
  public deletePlayerCharacter(id: number): void {
    this._http
      .delete(`${environment(`player/${id}`).uri}`, {
        headers: { authorization: `Bearer ${this._authService.getToken()}` },
      })
      .pipe(take(1))
      .subscribe();
  }

  // Arguments : ------
  // Résultat : Retourne toutes les races stockées en data
  public getAllRaces$(): Observable<RaceDetails[]> {
    return this._http.get(environment(`creation/races`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<RaceDetails[]>;
  }

  // Arguments : ------
  // Résultat : Retourne toutes les statistiques stockées en data
  public getAllStats$(): Observable<StatsDetails[]> {
    return this._http.get(environment(`creation/stats`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<StatsDetails[]>;
  }

  // Arguments : Code de la race
  // Résultat : Retourne tous les noms d'une race stockés en data
  public getRandomNamesByRace$(
    raceCode: string,
  ): Observable<RandomNamesCharacter> {
    return this._http.get(
      `${environment(`creation/randomnames/${raceCode}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<RandomNamesCharacter>;
  }

  // Arguments : ------
  // Résultat : Retourne toutes personnalités races stockées en data
  public getAllPersonalities$(): Observable<PersonalityDetails[]> {
    return this._http.get(environment(`creation/personalities`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PersonalityDetails[]>;
  }

  // Arguments : ------
  // Résultat : Retourne toutes divinités races stockées en data
  public getAllDivinities$(): Observable<DivinityDetails[]> {
    return this._http.get(environment(`creation/divinities`).uri, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<DivinityDetails[]>;
  }
}
