import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestState } from '@t12/store/quests';

const selectRoot = createFeatureSelector<QuestState>('quest');

export const getQuestsInfos = createSelector(
  selectRoot,
  (questsInfos: QuestState) => questsInfos.quests,
);

export const getActiveGoal = createSelector(
  selectRoot,
  (questsInfos: QuestState) => questsInfos.activeGoal,
);
