import { ChatLogKind } from '../enums/chat-log.enums';
import { ChatCommands } from '../types/chat-commands.type';

export const allChatCommands: ChatCommands = {
  [ChatLogKind.Roll]: ['roll', 'r'],
  [ChatLogKind.Action]: ['action', 'me'],
  [ChatLogKind.All]: ['all', 'a'],
};

export const talkCommands = [ChatLogKind.Talk, ChatLogKind.Yell, ChatLogKind.Pm, ChatLogKind.Party];
