import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BankState } from '@t12/store/bank';
import { nbItemsPageBank } from '@t12/bank/constants/nb-items-page-bank.constant';
import { ItemQuality } from '@t12/common/item/enums/item-quality.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';

const selectRoot = createFeatureSelector<BankState>('bank');

export const getBank = createSelector(selectRoot, (bank: BankState) => bank);

export const getLvlBank = createSelector(selectRoot, ({ lvl }) => lvl);

export const getGoldBank = createSelector(selectRoot, ({ gold }) => gold);

export const getItemsBank = createSelector(selectRoot, ({ items }) => items);

export const getDisplayedItemsBank = createSelector(
  selectRoot,
  ({ items, currentPage }) => {
    const slicedItems = items.slice(
      (currentPage - 1) * nbItemsPageBank,
      currentPage * nbItemsPageBank,
    );

    return slicedItems.concat(
      Array(nbItemsPageBank - slicedItems.length).fill({
        amount: 0,
        quality: ItemQuality.Common,
      }),
    ) as Item[];
  },
);

export const getCurrentPageBank = createSelector(
  selectRoot,
  ({ currentPage }) => currentPage,
);

export const getMaxPageBank = createSelector(
  selectRoot,
  ({ maxPage }) => maxPage,
);
