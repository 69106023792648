import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { ContainerState } from '@t12/store/container';

export const ContainerActions = createActionGroup({
  source: 'CONTAINER',
  events: {
    Init: emptyProps(),
    'Remove item': props<{ itemCode: string }>(),
    'Set container': props<{ container: ContainerState }>(),
    'Set current page': props<{ page: number }>(),
    'Set items displayed': props<{ items: Item[] }>(),
    'Set max page': props<{ maxPage: number }>(),
  },
});
