import { createReducer, on } from '@ngrx/store';
import { BankActions } from '@t12/store/bank/actions/bank.actions';
import { BankState, initialBankState } from '../index';
import { bankSortItems } from '@t12/common/bank/sort-items/bank-sort-items.function';

export const BankReducer = createReducer(
  initialBankState,

  on(BankActions.init, () => ({
    ...initialBankState,
  })),

  on(BankActions.setBank, (bankState: BankState, { bank }) => ({
    ...initialBankState,
    ...bank,
  })),

  on(BankActions.addGold, (bankState: BankState, { amountGold }) => ({
    ...bankState,
    gold: bankState.gold + amountGold,
  })),

  on(BankActions.setItems, (bankState: BankState, { items }) => {
    const sortedItemsBank = JSON.parse(JSON.stringify(items)).sort(
      bankSortItems,
    );
    return {
      ...bankState,
      items: sortedItemsBank,
    };
  }),

  on(BankActions.setCurrentPage, (bankState: BankState, { page }) => ({
    ...bankState,
    currentPage: page,
  })),
);
