import { Injectable } from '@angular/core';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveTargetService {
  private _target$: BehaviorSubject<Character> = new BehaviorSubject(null);
  private _timerRemoveTarget;

  // Argument : ------
  // Résultat : Retourne toutes les infos de la dernière cible blessée
  public getActiveTarget$(): BehaviorSubject<Character> {
    return this._target$;
  }

  // Argument : Personnage
  // Résultat : Met à jour les infos de la dernière cible blessé
  public setActiveTarget(target: Character): void {
    clearTimeout(this._timerRemoveTarget);
    this._target$.next(target);
    this.timerRemoveActiveTarget();
  }

  // Argument : ------
  // Résultat : Cache les infos de la cible au bout de 3 secondes
  public timerRemoveActiveTarget(): void {
    this._timerRemoveTarget = setTimeout(() => {
      this._target$.next(null);
    }, 3000);
  }
}
