import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { Recipe } from '@t12/common/job/interfaces/recipe.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JOB_FEATURE_KEY = 'job';

export interface JobState {
  activeJobIndex: number;
  activeJobKind: JobKind;
  activeTab: TabJob;
  recipes: Recipe[];
  displayedRecipes: Recipe[];
  currentPage: number;
  maxPage: number;
  workshop: Workshop;
}

export const initialJobState: JobState = {
  activeJobIndex: null,
  activeJobKind: null,
  activeTab: TabJob.Jobs,
  recipes: null,
  displayedRecipes: null,
  currentPage: 1,
  maxPage: 1,
  workshop: null,
};
