export enum ChatLogColor {
  Action = '#e6ac12',
  Bonus = '#28b436',
  Default = 'white',
  Event = '#ff9021',
  Log = '#ffe100',
  Malus = '#ff4141',
  Roll = 'pink',
}

export enum ChatLogScope {
  Local = 'local',
  All = 'all',
}

export enum ChatLogKind {
  Talk = 'talk',
  Yell = 'yell',
  Roll = 'roll',
  Action = 'action',
  All = 'all',
  Pm = 'pm',
  Party = 'party',
  Log = 'log',
  Bonus = 'bonus',
  Malus = 'malus',
}
