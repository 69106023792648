import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { QuestGoal } from '@t12/common/quest/interfaces/quest-goal.inferface';
import { QuestGoalKind } from '@t12/common/quest/types/quest-goal-kind.type';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { InventoryUtilsService } from '@t12/inventory/services/inventory-utils/inventory-utils.service';
import { QuestActions } from '@t12/store/quests/actions/quest.actions';
import { getQuestsInfos } from '@t12/store/quests/selectors/quest.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class QuestGoalsService {
  constructor(
    private readonly _notificationService: NotificationManagerService,
    private readonly _inventoryUtilsService: InventoryUtilsService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  public findGoalFromPlayerQuest(
    entityCode: string,
    kind: QuestGoalKind,
  ): QuestGoal {
    const questsInfos = this._utils.getSelect(getQuestsInfos);
    const allGoals = questsInfos.flatMap((questInfos) => questInfos.goals);

    return allGoals.find(
      (goal) => goal.entityCode === entityCode && goal.kind === kind,
    );
  }

  public addNotificationQuestGoal(goal: QuestGoal) {
    const { amount, amountTotal, name } = goal;
    const text = `${amount}/${amountTotal} : ${name}`;

    const isQuestDone = amount === amountTotal;
    const suffix = `${isQuestDone ? ' (terminé)!' : ''}`;

    switch (goal.kind) {
      case 'exploration':
        this._notificationService.addNotification(
          'quest',
          `Vous avez trouvé ${name}`,
        );
        break;
      case 'kill':
        this._notificationService.addNotification('quest', `${text}${suffix}`);
        break;
    }
  }

  // Argument : ------
  // Résultat : Met à jour tous les objectifs de quête de collecte
  public updateCollectGoals() {
    const quests = this._utils.getSelect(getQuestsInfos);
    const questsInfos = quests.map((quest) => {
      return {
        ...quest,
        goals: quest.goals.map((goal) => ({
          ...goal,
          amount:
            goal.kind === 'collect'
              ? Math.min(
                  this._inventoryUtilsService.countItemInInventory(
                    goal.entityCode,
                  ),
                  goal.amountTotal,
                )
              : goal.amount,
        })),
      };
    });

    this._store.dispatch(QuestActions.setQuests({ questsInfos }));
  }

  public findCharacterInGoalQuest(entityCode: string): QuestInfos {
    const quests = this._utils.getSelect(getQuestsInfos);

    for (const quest of quests) {
      const matchingGoal = quest.goals.find(
        (goal) => goal.entityCode === entityCode,
      );
      if (matchingGoal) {
        return quest;
      }
    }
  }
}
