import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { PlayerDeathService } from '@t12/player/services/player-death/player-death.service';
import { PlayerKillService } from '@t12/player/services/player-kill/player-kill.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getCharacters } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { take } from 'rxjs';
import { AttackKind } from '../../types/attack-kind.type';
import { HitKind } from '../../types/hit-kind.type';
import { ActiveTargetService } from '../active-target/active-target.service';
import { FightHitService } from '../fight-hit/fight-hit.service';

@Injectable({
  providedIn: 'root',
})
export class FightDmgService {
  constructor(
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _dbPlayer: PlayerDbService,
    private readonly _fightHitService: FightHitService,
    private readonly _playerDeathService: PlayerDeathService,
    private readonly _playerKillService: PlayerKillService,
    private readonly _store: Store,
    private readonly _timerService: TimersManagerService,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : Attaquant, Cible à toucher, type d'attaque, index tuile
  // Résultat : Indique si l'attaquant à toucher la cible
  public dealDmg(
    attacker: NPC | Monster | Player,
    target: NPC | Monster | Player,
    attack: AttackKind,
    hit: HitKind,
  ): void {
    if (attacker.health <= 0 || target.health <= 0) {
      return;
    }
    const dmgAndReduce = this._getDmgHit(attacker, target, attack, hit);
    this._fightHitService.soundHit(hit);
    this._store.dispatch(
      CharactersActions.addHealth({
        idCharacter: target.idCharacter,
        health: -dmgAndReduce[0],
      }),
    );

    if (target.isPlayer) {
      this._dbPlayer.updatePlayer().pipe(take(1)).subscribe();
    }
    this._fightHitService.displayChatLogHit(
      attacker,
      target,
      dmgAndReduce,
      attack,
      hit,
    );
    target = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === target.idCharacter);
    if (target.health <= 0 && !target.isPlayer) {
      this._timerService.stopTimerFightByID(target.idCharacter);
      const { code, kind } = target;

      this._dbPlayer
        .playerKillCharacter({ playerId: (attacker as Player).id, code, kind })
        .pipe(take(1))
        .subscribe((rewards) => {
          this._playerKillService.playerKillCharacter(target, rewards);
        });
      this._activeTargetService.setActiveTarget(null);
    } else if (target.isPlayer && target.health <= 0) {
      this._dbPlayer
        .updatePlayer()
        .pipe(take(1))
        .subscribe(() => {
          this._playerDeathService.playerDeath();
        });
    }
  }

  // Argument : Attaquant, Cible à toucher, type d'attaque, type de coup
  // Résultat : Retourne les dégats totaux et ceux potentiellement réduits
  private _getDmgHit(
    attacker: Character,
    target: Character,
    attack: string,
    hit: string,
  ): number[] {
    const { stats: attackerStats } = attacker;
    const { stats: targetStats } = target;
    const targetDef = targetStats.con;
    const targetDefMagic = targetStats.sag;
    const attackFor = attackerStats.for;
    const attackDex = attackerStats.dex;
    const attackInt = attackerStats.int;
    let coefDex = 0.3;

    // TODO A convertir en reduceDmg pour avoir le bonus d'attaque dans le dos dans le chat log
    if (attack === 'physic' && attacker.looking === target.looking) {
      coefDex = 0.6;
    }

    let dmg: number;
    let reduceDmg = 0;

    if (attack === 'magic') {
      dmg = Math.floor(
        (attackInt * (attackInt * 1.1)) / (attackInt + targetDefMagic),
      );
      dmg -= Math.floor(Math.random() * Math.ceil(dmg * 0.15));
    } else {
      const attackPower = attackFor + attackDex * coefDex;
      const totalDef = attackFor + attackDex * coefDex + targetDef;
      dmg = Math.floor((attackPower * attackPower) / totalDef);
      dmg -= Math.floor(Math.random() * Math.ceil(dmg * 0.25));
    }

    if (hit === 'parry' && attack === 'physic') {
      reduceDmg = -Math.ceil(dmg * 0.25);
      dmg += reduceDmg;
    } else if (hit === 'block') {
      reduceDmg = -Math.ceil(dmg * 0.5);
      dmg += reduceDmg;
    } else if (hit === 'avoid') {
      reduceDmg = dmg;
      dmg -= reduceDmg;
    } else if (hit === 'crit') {
      reduceDmg = dmg;
      dmg += dmg;
    }

    dmg = Math.max(0, dmg);
    return [dmg, reduceDmg];
  }
}
