import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from '@t12/store/chat';

const selectRoot = createFeatureSelector<ChatState>('chat');

export const getChat = createSelector(selectRoot, (chat: ChatState) => chat);

export const getActiveChat = createSelector(
  selectRoot,
  (chat: ChatState) => chat[chat.activeTab],
);

export const getActiveTabChat = createSelector(
  selectRoot,
  (chat: ChatState) => chat.activeTab,
);
