import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getPlayer } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerManagerService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Arguments : Nom d'une statistique, valeur à ajouter
  // Résultat : Met à jour la statistique précisée en l'incrémentant avec la valeur précisé
  public updateInfosPlayerCharacter(
    stat: 'health' | 'mana' | 'gold' | 'xp',
    value: number,
  ): void {
    const player: Player = this._utils.getSelect(getPlayer);

    switch (stat) {
      case 'health': {
        const healthMax = maxHealth(player.stats.con);
        if (player.health + value > healthMax) {
          this._store.dispatch(
            CharactersActions.setHealth({ idCharacter: 0, health: healthMax }),
          );
        } else {
          this._store.dispatch(
            CharactersActions.addHealth({ idCharacter: 0, health: value }),
          );
        }
        break;
      }
      case 'mana': {
        const manaMax = maxMana(player.stats.int);
        if (player.mana + value > manaMax) {
          this._store.dispatch(
            CharactersActions.setMana({ idCharacter: 0, mana: manaMax }),
          );
        } else {
          this._store.dispatch(
            CharactersActions.addMana({ idCharacter: 0, mana: value }),
          );
        }
        break;
      }
      case 'gold': {
        this._store.dispatch(
          CharactersActions.addGold({ idCharacter: 0, gold: value }),
        );
        break;
      }
      case 'xp': {
        this._store.dispatch(
          CharactersActions.addXp({ idCharacter: 0, xp: value }),
        );
        if (player.xp + value >= 100) this.levelUpPlayer();

        break;
      }
    }
  }

  public levelUpPlayer(): void {
    this._store.dispatch(CharactersActions.addLvl({ idCharacter: 0 }));
    const player = this._utils.getSelect(getPlayer);
    const { lvl, name, stats } = player;
    this._audioService.playSound('miscs', 'lvlup', 'wav');
    this._notificationService.addNotification(
      'item',
      `Vous venez de gagner un niveau ! (Lvl ${lvl})`,
    );
    this._chatService.addChatLog(
      'fight',
      name,
      `a gagné un niveau ! (Lvl ${lvl})`,
      ChatLogKind.Bonus,
    );

    this._store.dispatch(
      CharactersActions.setHealth({
        idCharacter: 0,
        health: maxHealth(stats.con),
      }),
    );
    this._store.dispatch(
      CharactersActions.setMana({ idCharacter: 0, mana: maxMana(stats.int) }),
    );
  }
}
