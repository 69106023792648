import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';

export const CHAT_FEATURE_KEY = 'chat';
export type TabChat = 'chat' | 'fight' | 'action';

export interface ChatState {
  action: ChatLog[];
  activeTab: TabChat;
  chat: ChatLog[];
  fight: ChatLog[];
}

export const initialChatState: ChatState = {
  chat: [],
  fight: [],
  action: [],
  activeTab: 'chat',
};
