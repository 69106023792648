import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { BehaviorSubject } from 'rxjs';
import { Notification } from '../../interfaces/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {
  public _notification$: BehaviorSubject<Notification> = new BehaviorSubject(
    null,
  );
  public _timerDisplay: any;
  public _timerCleanNotifications: any;

  constructor(private readonly _store: Store) {}

  // Arguments : Type notification, texte notif, temps d'affichage, picto de la notification
  // Résultat : Ajoute une notification pour pouvoir l'afficher
  public addNotification(
    type: string,
    text: string,
    delay?: number,
    img?: string,
  ): void {
    const notification: Notification = {
      type,
      text,
      img,
    };
    this._notification$.next(notification);
    this._displayNotification(delay);
  }

  // Argument : ------
  // Résultat : Renvoie la notification active et ses informations
  public getNotification(): BehaviorSubject<Notification> {
    return this._notification$;
  }

  // Argument : Temps d'affichage de la notif en ms
  // Résultat : Affiche une notification pendant une période limitée
  private _displayNotification(delay = 3000): void {
    clearTimeout(this._timerDisplay);
    clearTimeout(this._timerCleanNotifications);
    this._store.dispatch(HudDisplayActions.showHud({ name: 'notification' }));
    this._timerDisplay = setTimeout(() => {
      this._store.dispatch(HudDisplayActions.hideHud({ name: 'notification' }));
      this._timerCleanNotifications = setTimeout(() => {
        this._notification$.next(null);
      }, 300);
    }, delay);
  }
}
