import { createReducer, on } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { sortItemsByTypeNameAmount } from '@t12/common/inventory/functions/sort-items/sortItemsByTypeNameAmount.function';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { CharactersActions } from '../actions/characters.actions';
import { initialCharactersState } from '../index';
import { SpriteAnimationsX } from '@t12/characters/enums/sprite-animations-x.enum';

export const CharactersReducer = createReducer(
  initialCharactersState,

  on(CharactersActions.loadPlayerSuccess, (_, { player }) => {
    const playerCharacter = {
      ...player,
      canMove: true,
      isPlayer: true,
      kind: 'player' as const,
      top: (player.position.y - 1) * 32,
      left: player.position.x * 32 - 2,
      spriteX: SpriteAnimationsX.IDLE_STEP_X,
      idCharacter: 0,
      firstStep: false,
    };

    return [playerCharacter];
  }),

  on(
    CharactersActions.addCharacter,
    (charactersState: Character[], { character }) => [
      ...charactersState,
      character,
    ],
  ),

  on(
    CharactersActions.setCharacters,
    (charactersState: Character[], { characters }) => [
      charactersState[0],
      ...characters,
    ],
  ),

  on(CharactersActions.resetCharacters, (charactersState: Character[]) => [
    charactersState[0],
  ]),

  on(
    CharactersActions.setPlayerTrackId,
    (charactersState: Character[], { trackId }) => {
      const [updatePlayer, ...restCharacters] = charactersState;
      return [{ ...updatePlayer, trackId }, ...restCharacters];
    },
  ),

  on(
    CharactersActions.addPositionX,
    (charactersState: Character[], { idCharacter, x }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { ...character.position, x: character.position.x + x },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addPositionY,
    (charactersState: Character[], { idCharacter, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { ...character.position, y: character.position.y + y },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addPositionXY,
    (charactersState: Character[], { idCharacter, x, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { x: character.position.x + x, y: character.position.y + y },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setPositionXY,
    (charactersState: Character[], { idCharacter, x, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { x, y },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addTop,
    (charactersState: Character[], { idCharacter, top }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        top: character.top + top,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addLeft,
    (charactersState: Character[], { idCharacter, left }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        left: character.left + left,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setTop,
    (charactersState: Character[], { idCharacter, top }) => {
      const updateCharacter = (character: Character) => ({ ...character, top });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setLeft,
    (charactersState: Character[], { idCharacter, left }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        left,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setLooking,
    (charactersState: Character[], { idCharacter, looking }) => {
      let spriteY = 0;
      if (looking === 'right') {
        spriteY = -55;
      } else if (looking === 'down') {
        spriteY = -110;
      } else if (looking === 'left') {
        spriteY = -165;
      }
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY,
        looking,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addSpriteX,
    (charactersState: Character[], { idCharacter, spriteX }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteX: character.spriteX + spriteX,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addSpriteY,
    (charactersState: Character[], { idCharacter, spriteY }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY: character.spriteY + spriteY,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setSpriteX,
    (charactersState: Character[], { idCharacter, spriteX }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteX,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setSpriteY,
    (charactersState: Character[], { idCharacter, spriteY }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setCanMove,
    (charactersState: Character[], { idCharacter, canMove }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        canMove,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.toggleFirstStep,
    (charactersState: Character[], { idCharacter }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        firstStep: !character.firstStep,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setWorldCode,
    (charactersState: Character[], { idCharacter, worldCode }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        worldCode,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),
  on(
    CharactersActions.setHealthManaGold,
    (charactersState: Character[], { idCharacter, health, mana, gold }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health,
        mana,
        gold,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),
  on(
    CharactersActions.addHealth,
    (charactersState: Character[], { idCharacter, health }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health: character.health + health < 0 ? 0 : character.health + health,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setHealth,
    (charactersState: Character[], { idCharacter, health }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addMana,
    (charactersState: Character[], { idCharacter, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        mana: character.mana + mana,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setMana,
    (charactersState: Character[], { idCharacter, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        mana,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addStat,
    (charactersState: Character[], { idCharacter, stat, amount }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        stats: { ...character.stats, [stat]: character.stats[stat] + amount },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setStats,
    (charactersState: Character[], { idCharacter, stats }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        stats,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addLvl,
    (charactersState: Character[], { idCharacter }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        lvl: character.lvl + 1,
        stats: {
          for: character.stats.for + 1,
          con: character.stats.con + 1,
          dex: character.stats.dex + 1,
          int: character.stats.int + 1,
          sag: character.stats.sag + 1,
          cha: character.stats.cha,
        },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setLvl,
    (charactersState: Character[], { idCharacter, lvl }) => {
      const updateCharacter = (character: Character) => ({ ...character, lvl });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addXp,
    (charactersState: Character[], { idCharacter, xp }) => {
      return charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? {
              ...character,
              xp:
                character.xp + xp < 100
                  ? character.xp + xp
                  : (character.xp + xp) % 100,
            }
          : character,
      );
    },
  ),

  on(
    CharactersActions.setXp,
    (charactersState: Character[], { idCharacter, xp }) => {
      const updateCharacter = (character: Character) => ({ ...character, xp });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setState,
    (charactersState: Character[], { idCharacter, state }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        state,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addGold,
    (charactersState: Character[], { idCharacter, gold }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        gold: character.gold + gold,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setGold,
    (charactersState: Character[], { idCharacter, gold }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        gold,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setInventory,
    (charactersState: Character[], { idCharacter, inventory }) => {
      const sortInventory = JSON.parse(JSON.stringify(inventory)).sort(
        sortItemsByTypeNameAmount,
      );
      const updateCharacter = (character): Player => ({
        ...character,
        inventory: sortInventory,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setEquipment,
    (charactersState: Character[], { idCharacter, index, equipment }) => {
      const updateCharacter = (character): Player => ({
        ...character,
        equipments: [
          ...character.equipments.slice(0, index),
          equipment,
          ...character.equipments.slice(index + 1),
        ],
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setEquipments,
    (charactersState: Character[], { idCharacter, equipments }) => {
      const updateCharacter = (character): Player => ({
        ...character,
        equipments,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),
  on(
    CharactersActions.removeEquipment,
    (charactersState: Character[], { idCharacter, index }) => {
      const updateCharacter = (character): Player => ({
        ...character,
        equipments: [
          ...character.equipments.slice(0, index),
          { code: '', amount: 0 },
          ...character.equipments.slice(index + 1),
        ],
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setHaveQuest,
    (charactersState: Character[], { idCharacter, hasQuest }) => {
      const updateCharacter = (character) => ({
        ...character,
        quest: {
          ...character.quest,
          hasQuest,
        },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setIsDoneQuest,
    (charactersState: Character[], { idCharacter, isDone }) => {
      const updateCharacter = (character) => ({
        ...character,
        quest: {
          ...character.quest,
          isDone: isDone,
        },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addNewJob,
    (charactersState: Character[], { idCharacter, job }) => {
      const updateCharacter = (character) => ({
        ...character,
        jobs: {
          ...character.jobs,
          [job.kind]: [...character.jobs[job.kind], job],
        },
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(CharactersActions.removeJob, (charactersState, { idCharacter, job }) => {
    const updateCharacter = (character) => ({
      ...character,
      jobs: {
        ...character.jobs,
        [job.kind]: character.jobs[job.kind].filter(
          (jobItem: PlayerJob) => jobItem.code !== job.code,
        ),
      },
    });
    const updatedState = charactersState.map((character) =>
      character.idCharacter === idCharacter
        ? updateCharacter(character)
        : character,
    );

    return [...updatedState];
  }),

  on(
    CharactersActions.setJobs,
    (charactersState: Character[], { idCharacter, jobs }) => {
      const updateCharacter = (character): Player => ({
        ...character,
        jobs,
      });
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setActiveJobXp,
    (charactersState: Character[], { idCharacter, kind, index, xp }) => {
      const updateCharacter = (character: Player): Player => {
        const updatedJobs = JSON.parse(JSON.stringify(character.jobs));
        updatedJobs[kind][index].xp = xp;
        return {
          ...character,
          jobs: updatedJobs,
        };
      };
      const updatedState = charactersState.map((character) =>
        character.idCharacter === idCharacter
          ? updateCharacter(character as Player)
          : character,
      );

      return [...updatedState];
    },
  ),
);
