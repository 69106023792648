import { Injectable } from '@angular/core';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { ResultCraft } from '@t12/common/job/interfaces/result-craft.interface';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { JobManagerService } from '../job-manager/job-manager.service';

@Injectable({
  providedIn: 'root',
})
export class RecipeCraftService {
  constructor(
    private readonly _itemDepositService: ItemDepositService,
    private readonly _jobService: JobManagerService,
    private readonly _notificationService: NotificationManagerService,
  ) {}

  public craftRecipe(resultCraft: ResultCraft, recipe) {
    const { playerJobXp, product } = resultCraft;

    recipe.ingredients.forEach((ingredient: Item) => {
      this._itemDepositService.removeItemFrom(
        ContainerKind.Inventory,
        ingredient.code,
        ingredient.amount,
      );
    });

    if (playerJobXp) {
      const job = this._jobService.getJob(recipe.jobCode);
      this._jobService.setXpToActiveJob(job, playerJobXp);
    }

    if (!product) {
      this._notificationService.addNotification(
        'error',
        'Fabrication ratée...',
      );
    } else {
      this._itemDepositService.addItemInto(
        ContainerKind.Inventory,
        product,
        product.amount,
      );
    }
  }
}
