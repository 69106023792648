import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogActions } from '@t12/store/dialog/actions/dialog.actions';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { take } from 'rxjs';
import { DialogDbService } from '../dialog-db/dialog-db.service';

@Injectable({
  providedIn: 'root',
})
export class DialogQuestService {
  constructor(
    private readonly _dialogDb: DialogDbService,
    private readonly _store: Store,
  ) {}

  // Argument : ------
  // Résultat : Initialise le dialogue en mettant à jour ses variables
  public getDialogQuest(questCode: string, npcCode: string): void {
    this._dialogDb
      .getDialogQuest(questCode, npcCode)
      .pipe(take(1))
      .subscribe((textsDialog) => {
        this._store.dispatch(DialogActions.setDialog({ textsDialog }));
        this._store.dispatch(HudDisplayActions.showHud({ name: 'dialog' }));
      });
  }
}
