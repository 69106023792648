import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { IAddKnowledge } from '@t12/common/job/interfaces/add-job.interface';
import { CraftRecipe } from '@t12/common/job/interfaces/craft-recipe.interface';
import { Recipe } from '@t12/common/job/interfaces/recipe.interface';
import { ResultCraft } from '@t12/common/job/interfaces/result-craft.interface';
import { CodeJob } from '@t12/common/job/types/code-job.type';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { getPlayerID } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable, Subscription, take } from 'rxjs';
import { RecipeCraftService } from '../recipe-craft/recipe-craft.service';

@Injectable({
  providedIn: 'root',
})
export class RecipeDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _recipeCraftService: RecipeCraftService,
    private readonly _utilsService: UtilsService,
  ) {}

  public getPlayerJobRecipes(jobCode: CodeJob): Observable<Recipe[]> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(
      `${environment(`recipe/${jobCode}/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<Recipe[]>;
  }

  public learnRecipe(itemCode: string): Observable<Item> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const addKnowledgeDto: IAddKnowledge = {
      playerId,
      itemCode,
    };

    return this._http.post(
      `${environment(`recipe/player/learn`).uri}`,
      addKnowledgeDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<Item>;
  }

  public craftRecipe(recipe: Recipe): Subscription {
    const playerId = this._utilsService.getSelect(getPlayerID);

    const addKnowledgeDto: CraftRecipe = {
      playerId,
      recipeCode: recipe.code,
      amount: 1,
    };

    return this._http
      .post(`${environment(`recipe/craft`).uri}`, addKnowledgeDto, {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      })
      .pipe(take(1))
      .subscribe((resultCraft: ResultCraft) => {
        this._recipeCraftService.craftRecipe(resultCraft, recipe);
      });
  }
}
