import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { QuestsManagerService } from '@t12/quest/services/quests-manager/quests-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { DialogActions } from '@t12/store/dialog/actions/dialog.actions';
import {
  getDialog,
  getDialogCharacter,
} from '@t12/store/dialog/selectors/dialog.selectors';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { ShopActions } from '@t12/store/shop/actions/shop.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { ShopManagerService } from '@t12/shop/services/shop/shop-manager.service';
import { DialogInitService } from '../dialog-init/dialog-init.service';
import { DialogQuestService } from '../dialog-quest/dialog-quest.service';

@Injectable({
  providedIn: 'root',
})
export class DialogManagerService {
  constructor(
    private readonly _dialogInit: DialogInitService,
    private readonly _dialogQuestService: DialogQuestService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questService: QuestsManagerService,
    private readonly _shopService: ShopManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : ------
  // Résultat : Incrémente l'index du texte du dialogue pour afficher la suite
  public nextMsg(): void {
    this._store.dispatch(DialogActions.nextMessage());
  }

  public isNextMsg(): boolean {
    const dialog = this._utils.getSelect(getDialog);

    return dialog.texts[dialog.index + 1] !== undefined;
  }

  // Argument : Texte dialogue à placer
  // Résultat : Ajoute le dialog en argument puis continue le dialog
  public setTextDialog(text: string): void {
    if (!text) {
      this._dialogInit.endDialog();
      return;
    }

    let { index, texts } = this._utils.getSelect(getDialog);
    texts.splice(index + 1, 0, { text });

    this._store.dispatch(
      DialogActions.setDialog({
        textsDialog: texts.slice(index + 1),
      }),
    );
  }

  // Argument : Option choisie
  // Résultat : Met à jour le dialogue selon l'option choisie
  public chooseOption(option: OptionDialog): void {
    const npc = this._utils.getSelect(getDialogCharacter);
    const { idCharacter } = npc;

    if (option.label === 'Commerce') {
      this._store.dispatch(HudDisplayActions.hideHud({ name: 'dialog' }));
      this._store.dispatch(ShopActions.init());
      this._shopService.initShop(npc.code);
      return;
    }

    if (option.label === 'Stockage') {
      this._store.dispatch(HudDisplayActions.showHud({ name: 'bank' }));
      this._dialogInit.endDialog();
      return;
    }

    if (option.createQuest && !option.validateQuest) {
      this._questService.addQuest(option.createQuest, npc);
    }
    if (option.validateQuest) {
      if (!this._questService.isQuestFinished(option.validateQuest)) return;

      const activeQuest = this._questService.getQuestPlayer(
        option.validateQuest,
      );

      if (
        !this._questService.canGetRewardQuest(activeQuest.rewardItems as Item[])
      ) {
        const text =
          "Vous n'avez pas de place pour la récompense, videz vos poches.";
        this._store.dispatch(
          DialogActions.setDialog({ textsDialog: [{ text }] }),
        );
        this._notificationService.addNotification(
          'error',
          'Sac plein, faites de la place!',
        );
        return;
      }

      if (option.nextTextDialog) this.setTextDialog(option.nextTextDialog);

      this._questService.removeItemsQuest(activeQuest);
      this._questService.rewardQuest(activeQuest, idCharacter);
      this._questService.removeQuest(option.validateQuest);
      this._store.dispatch(
        CharactersActions.setIsDoneQuest({ idCharacter, isDone: undefined }),
      );
      if (option.createQuest) {
        this._questService.addQuest(option.createQuest, npc);
      }
    }

    if (option.nextDialogQuest) {
      this._store.dispatch(
        CharactersActions.setHaveQuest({
          idCharacter,
          hasQuest: true,
        }),
      );
      this._dialogQuestService.getDialogQuest(option.nextDialogQuest, npc.code);
    } else {
      this.setTextDialog(option.nextTextDialog);
    }
  }
}
