import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { ContainerActions } from '@t12/store/container/actions/container.actions';
import { getContainerItems } from '@t12/store/container/selectors/container.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { take } from 'rxjs';
import { ContainerDbService } from '../container-db/container-db.service';
import { ContainerUtilsService } from '../container-utils/container-utils.service';
import { ItemDepositService } from '../items-deposit/item-deposit.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerPickService {
  constructor(
    private readonly _containerDbService: ContainerDbService,
    private readonly _containerUtilsService: ContainerUtilsService,
    private readonly _itemDepositServiceService: ItemDepositService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {}

  public pickItem(itemCode: string) {
    this._containerDbService
      .pickContainerItem(itemCode)
      .pipe(take(1))
      .subscribe((item: Item) => {
        if (item) {
          this._itemDepositServiceService.addItemInto(
            ContainerKind.Inventory,
            item,
          );

          this._store.dispatch(
            ContainerActions.removeItem({ itemCode: item.code }),
          );
          this._containerUtilsService.setDisplayedContainerItems();
          const containerItems =
            this._utilsService.getSelect(getContainerItems);
          if (containerItems.length === 0)
            this._containerUtilsService.closeContainer();
        } else {
          this._store.dispatch(ContainerActions.removeItem({ itemCode }));
          this._containerUtilsService.setDisplayedContainerItems();
          this._notificationService.addNotification(
            'error',
            "Ce butin n'est plus disponible",
          );
        }
      });
  }
}
