import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { KnowledgeKind } from '@t12/common/job/enums/knowledge-kind.enum';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { CodeJob } from '@t12/common/job/types/code-job.type';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import {
  getPlayerJobs,
  getActivePlayerJob,
} from '@t12/store/characters/selectors/characters.selectors';
import { JobActions } from '@t12/store/job/actions/job.actions';
import { getActiveJobIndex } from '@t12/store/job/selectors/job.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable, take } from 'rxjs';
import { CraftsmanshipJobs } from '../../constants/craftsmanship-jobs.contants';
import { HobbyJobs } from '../../constants/hobby-jobs.contants';
import { knowledgeJobsConstant } from '../../constants/knowledge-jobs.constant';
import { TabJob } from '../../enums/tab-job.enum';
import { JobDbService } from '../job-db/job-db.service';

@Injectable({
  providedIn: 'root',
})
export class JobManagerService {
  constructor(
    private readonly _itemDepositService: ItemDepositService,
    private readonly _jobDb: JobDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {}

  public getActiveJob(): Observable<PlayerJob> {
    return this._store.select(getActivePlayerJob(this._utilsService));
  }

  public setActiveJob(kind: JobKind, jobCode: CodeJob): void {
    const playerJobs = this._utilsService.getSelect(getPlayerJobs);
    const index = playerJobs[kind].findIndex(
      (playerJob) => playerJob.code === jobCode,
    );
    this._store.dispatch(JobActions.setActiveJobKind({ kind }));
    this._store.dispatch(JobActions.setActiveJobIndex({ index }));
  }

  public removeActiveJob(): void {
    this._store.dispatch(JobActions.setActiveJobKind({ kind: null }));
    this._store.dispatch(JobActions.setActiveJobIndex({ index: null }));
  }

  public setActiveTabJob(tab: TabJob, jobCode?: CodeJob): void {
    this._store.dispatch(JobActions.setActiveTab({ tab }));

    if (jobCode) {
      const job = this.getJob(jobCode);
      this.setActiveJob(job.kind, jobCode);
    }
  }

  public getKindJobByCode(jobCode: CodeJob): JobKind {
    if (CraftsmanshipJobs.includes(jobCode)) {
      return JobKind.Craftsmanship;
    } else if (HobbyJobs.includes(jobCode)) {
      return JobKind.Hobby;
    }
  }

  public getJob(jobCode: CodeJob): PlayerJob {
    const playerJobs = this._utilsService.getSelect(getPlayerJobs);
    const jobKind = this.getKindJobByCode(jobCode);

    return playerJobs[jobKind].find((playerJob) => playerJob.code === jobCode);
  }

  public canInteractEntityJob(jobCode: CodeJob, requiredXp?: number): boolean {
    const job = this.getJob(jobCode);

    if (!job) {
      this._notificationService.addNotification(
        'error',
        `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
      );
      return false;
    }
    if (requiredXp && job.xp < requiredXp) {
      this._notificationService.addNotification(
        'error',
        `Expérience du métier trop bas! ${job.xp}<${requiredXp}XP`,
      );
      return false;
    }
    this.setActiveJob(job.kind, job.code);

    return true;
  }

  setXpToActiveJob(job: PlayerJob, xp: number) {
    const index = this._utilsService.getSelect(getActiveJobIndex);

    this._notificationService.addNotification(
      'job',
      `Vous avez gagné 1XP pour le métier : ${job.name}`,
    );
    this._store.dispatch(
      CharactersActions.setActiveJobXp({
        idCharacter: 0,
        kind: job.kind,
        index,
        xp,
      }),
    );
  }

  addJob(
    itemCode: string,
    jobCode: CodeJob,
    knowledgeKind: KnowledgeKind,
  ): void {
    if (
      !knowledgeJobsConstant.includes(knowledgeKind) ||
      !this.canLearnJob(jobCode, knowledgeKind)
    )
      return;

    this._jobDb
      .addJob(itemCode)
      .pipe(take(1))
      .subscribe((job: PlayerJob) => {
        this._store.dispatch(
          CharactersActions.addNewJob({ idCharacter: 0, job }),
        );
        this._notificationService.addNotification(
          'job',
          `Nouveau métier appris: ${job.name}`,
        );
        this._itemDepositService.removeItemFrom(
          ContainerKind.Inventory,
          itemCode,
        );
      });
  }

  canLearnJob(jobCode: CodeJob, knowledgeKind: KnowledgeKind): boolean {
    const playerJobs = this._utilsService.getSelect(getPlayerJobs);
    const craftsmanship = KnowledgeKind.Craftsmanship;
    if (
      knowledgeKind === craftsmanship &&
      playerJobs[craftsmanship].length >= 2
    ) {
      this._notificationService.addNotification(
        'error',
        "Vous ne pouvez apprendre que deux métiers d'artisanat.",
      );
      return false;
    }

    const alreadyHaveJob = playerJobs[knowledgeKind].find(
      (playerJob: PlayerJob) => playerJob.code === jobCode,
    );
    if (alreadyHaveJob) {
      this._notificationService.addNotification(
        'error',
        'Vous connaissez déjà ce métier.',
      );
      return false;
    }

    return true;
  }

  removeJob(job: PlayerJob): void {
    const { code: jobCode } = job;
    this._jobDb.removeJob(jobCode).pipe(take(1)).subscribe();
    this._store.dispatch(CharactersActions.removeJob({ idCharacter: 0, job }));
    this._notificationService.addNotification(
      'job',
      `Vous avez oublié le métier: ${job.name}`,
    );
  }
}
