import { createReducer, on } from '@ngrx/store';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { UserActions } from '../actions/user.actions';
import { initialUserState, UserState } from '../index';

export const UserReducer = createReducer(
  initialUserState,

  on(UserActions.init, () => ({
    ...initialUserState,
  })),

  on(UserActions.changeMail, (userState: UserState, { email }) => ({
    ...userState,
    email,
  })),

  on(UserActions.changeMusicState, (userState: UserState, { music }) => ({
    ...userState,
    settings: {
      ...userState.settings,
      music,
    },
  })),

  on(UserActions.changeNoisesState, (userState: UserState, { noises }) => ({
    ...userState,
    settings: {
      ...userState.settings,
      noises,
    },
  })),

  on(UserActions.changeLanguage, (userState: UserState, { language }) => ({
    ...userState,
    language,
  })),

  on(UserActions.loginUser, (userState: UserState) => ({
    ...userState,
    loadingStatus: LoadingStatus.IN_PROGRESS,
  })),

  on(UserActions.loginUserSuccess, (userState: UserState, { user }) => ({
    ...userState,
    email: user.email,
    settings: user.settings,
    language: user.language,
  })),

  on(UserActions.registerLoginUserFail, (userState: UserState, { error }) => ({
    ...userState,
    loadingStatus: LoadingStatus.NOT_LOADED,
    error,
  })),

  on(UserActions.loginRedirectPage, (userState: UserState, { email }) => ({
    ...userState,
    email,
    loadingStatus: LoadingStatus.LOADED,
    error: undefined,
  })),

  on(UserActions.logout, (userState: UserState) => ({
    ...initialUserState,
    language: userState.language,
  })),

  on(UserActions.loadUser, (userState: UserState, { user }) => ({
    ...userState,
    email: user.email,
    settings: user.settings,
    language: user.language,
    loadingStatus: LoadingStatus.LOADED,
    error: undefined,
  })),
);
