import { createReducer, on } from '@ngrx/store';
import { HudDisplayState, initialHudDisplayState } from '../index';
import { HudDisplayActions } from '../actions/hud-display.actions';

export const HudDisplayReducer = createReducer(
  initialHudDisplayState,

  on(HudDisplayActions.reset, () => initialHudDisplayState),

  on(HudDisplayActions.showHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: true,
  })),

  on(HudDisplayActions.hideHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: false,
  })),

  on(HudDisplayActions.toggleHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: !hotkeyState[props.name],
  })),
);
