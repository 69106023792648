import { Injectable } from '@angular/core';
import { EquipmentSlotIndex } from '@t12/common/equipments/enums/equipment-slot-index.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { PopOverManagerService } from '@t12/hud/services/pop-over/pop-over-manager.service';
import { InventoryPickService } from '@t12/inventory/services/inventory-pick/inventory-pick.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { getPlayer } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { EquipmentsDbService } from '../equipments-db/equipments-db.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _equipmentsDbService: EquipmentsDbService,
    private readonly _inventoryPickService: InventoryPickService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _utils: UtilsService,
  ) {}

  public canEquipItem(newEquipment: Item): boolean {
    const player = this._utils.getSelect(getPlayer);
    const indexSlotEquipment = EquipmentSlotIndex[newEquipment.slot];
    const actualEquipment = player.equipments[indexSlotEquipment];

    if (newEquipment.code === actualEquipment.code) {
      this._notificationService.addNotification(
        'error',
        'Vous portez déjà cet équipement!',
      );
      return false;
    } else if (newEquipment.lvl > player.lvl) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas le niveau pour ça!",
      );
      return false;
    }
    return true;
  }

  // Argument : Equipement à équiper
  // Résultat : Permet d'équiper une arme ou une armure et mettre à jour les stats du personnage
  public addEquipment(newEquipment: Item): void {
    this._audioService.playSoundEquip(newEquipment.slot);
    this._equipmentsDbService.addEquipment(newEquipment.code);
  }

  // Argument : Equipement à retirer
  // Résultat : Retire un équipement du personnage et met à jour ses stats
  public removeEquipment(equipment: Item): void {
    const indexPickItem = this._inventoryPickService.canPickItem(equipment);
    if (indexPickItem !== -1) {
      this._popoverService.deletePopOver();
      this._audioService.playSoundEquip(equipment.slot);
      this._equipmentsDbService.removeEquipment(equipment.code);
    }
  }
}
