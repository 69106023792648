import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../index';

const selectRoot = createFeatureSelector<UserState>('user');

export const getUser = createSelector(selectRoot, (user: UserState) => user);

export const getUserMail = createSelector(selectRoot, ({ email }) => email);

export const getUserSettings = createSelector(selectRoot, ({ settings }) => ({
  music: settings.music,
  noises: settings.noises,
}));

export const getUserLanguage = createSelector(
  selectRoot,
  ({ language }) => language,
);

export const getUserLoadingStatus = createSelector(
  selectRoot,
  ({ loadingStatus }) => loadingStatus,
);

export const getUserError = createSelector(selectRoot, ({ error }) => error);
