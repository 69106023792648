import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Looking } from '@t12/common/characters/types/looking.type';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { World } from '@t12/common/world/interfaces/world.interface';
import { SidesTiles } from '@t12/common/world/types/sides-tile.type';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getCharacters } from '@t12/store/characters/selectors/characters.selectors';
import { BehaviorSubject, take } from 'rxjs';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { emptyWorld } from '../../constants/empty-world.constant';
import { WorldDbService } from '../world-db/world-db.service';

@Injectable({
  providedIn: 'root',
})
export class WorldGeneratorService {
  private _world$: BehaviorSubject<World> = new BehaviorSubject(null);
  private _loadingWorld$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _trackId = 1;

  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _dbWorldService: WorldDbService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
    private readonly _timersService: TimersManagerService,
  ) {}

  public getLoadingState(): BehaviorSubject<boolean> {
    return this._loadingWorld$;
  }

  public setLoadingState(value: boolean): void {
    this._loadingWorld$.next(value);
  }

  private _getTrackId(): number {
    this._trackId++;
    return this._trackId;
  }
  // Argument : ------
  // Résultat : Reset la map
  public initWorld(): void {
    this._world$.next(emptyWorld);
  }

  // Argument : ------
  // Résultat : Reset la map
  public resetWorld(): void {
    this._timersService.stopAllTimersFight();
    this._timersService.stopAllTimersMove();

    this._store.dispatch(CharactersActions.resetCharacters());
  }

  // Argument : ------
  // Résultat : Retourne la map active
  public getWorld$(): BehaviorSubject<World> {
    return this._world$;
  }

  // Argument: Nom de la carte
  // Résultat: Importe la carte d'après le nom renseigné
  public loadMap(): void {
    this._loadingWorld$.next(true);

    this._dbWorldService
      .getWorld$()
      .pipe(take(1))
      .subscribe((world) => {
        this.setWorld(world);
      });
  }

  // Argument: Portail, personnage du joueur
  // Résultat: Téléporte le joueur selon les infos du portail
  public initWarp(warp: Warp): void {
    const { position, worldCode } = warp;
    this.resetWorld();
    this._store.dispatch(
      CharactersActions.setWorldCode({
        idCharacter: 0,
        worldCode: worldCode,
      }),
    );
    this._store.dispatch(
      CharactersActions.setPositionXY({
        idCharacter: 0,
        x: position.x,
        y: position.y,
      }),
    );
    this._store.dispatch(
      CharactersActions.setTop({ idCharacter: 0, top: (position.y - 1) * 32 }),
    );
    this._store.dispatch(
      CharactersActions.setLeft({ idCharacter: 0, left: position.x * 32 - 2 }),
    );
  }

  // Argument : Ligne et index de la tuile (Y et X)
  // Résultat : Retourne les infos d'une tuile de la map précise
  public getTile(y: number, x: number): Tile {
    return this._world$.value?.tiles[y]?.[x];
  }

  // Argument : Numéro de la tuile, type du personnage qui bouge
  // Résultat : Vérifie que la tuile envoyée est accessible pour s'y déplacer
  // TODO A améliorer
  public canStepOnTile(character: Character): boolean {
    let x = character.position.x;
    let y = character.position.y;

    if (!this._world$.value.tiles[y]?.[x]) return false;

    const currentTile: Tile = this._world$.value.tiles[y][x];
    let nextTile: Tile;
    if (character.looking === 'up') {
      y--;
    } else if (character.looking === 'down') {
      y++;
    } else if (character.looking === 'right') {
      x++;
    } else if (character.looking === 'left') {
      x--;
    }
    if (!this._world$.value.tiles[y]) return false;

    nextTile = this._world$.value.tiles[y][x];

    return !this._canMoveToNextTile(
      character.looking,
      nextTile,
      currentTile.sides,
      { x, y },
    );
  }

  // Argument : Nom de l'entité, entité à placer, position Y et X de la tuile
  // Résultat : Ajoute une entité selon le nom renseigné sur une tuile précise
  public addEntity(
    nameEntity: string,
    entity: Character | Item | Warp | Tile,
    y: number,
    x: number,
  ): void {
    this._world$.value.tiles[y][x][nameEntity] = entity;
  }

  // Argument : Tuile de l'objet à placer, item à placer
  // Résultat : Ajoute un objet sur une tuile
  public addItemTile(tile: Tile, dropItem: Item): void {
    if (tile.item && dropItem && tile.item.code === dropItem.code) {
      tile.item.amount += dropItem.amount;
    } else if (!tile.item) {
      tile.item = dropItem;
    }
  }

  // Argument : Nom de l'entité, position Y et X de la tuile
  // Résultat : Retire une entité selon le nom renseigné sur une tuile précise
  public removeEntity(
    nameEntity: 'item' | 'container' | 'character' | 'harvestPoint',
    y: number,
    x: number,
  ): void {
    this._world$.value.tiles[y][x][nameEntity] = null;
  }

  // Argument : ------
  // Résultat : Change le tableau de map actif
  public setWorld(world: World, warp?: Warp): void {
    if (warp) this.initWarp(warp);
    this._world$.next(world);
    this._loadingWorld$.next(false);

    this._store.dispatch(
      CharactersActions.setPlayerTrackId({ trackId: this._getTrackId() }),
    );
    this._generatePNJ(world.characters);
    this._audioService.playBackgroundMusic(world.music || 'home');
  }

  // Argument : ------
  // Résultat : Ajoute les PNJ du monde actif
  private _generatePNJ(characters: Character[]): void {
    if (characters.length === 0) return;
    const charactersWithId = characters.map((character) => ({
      ...character,
      idCharacter: this._getTrackId(),
      trackId: this._getTrackId(),
    }));
    this._store.dispatch(
      CharactersActions.setCharacters({ characters: charactersWithId }),
    );
  }

  // Argument : Index de la tuile, direction déplacement
  // Résultat : Vérifie si le déplacement entre deux tuiles est possible selon la direction du déplacement
  // TODO Inverser le boolean de cette méthode, vérifiez qu'on a accès et pas l'inverse wtf
  private _canMoveToNextTile(
    direction: Looking,
    nextTile: Tile,
    currentTileSides: SidesTiles = [false, false, false, false],
    { x, y }: Position,
  ): boolean {
    const characterFound = this._utils
      .getSelect(getCharacters)
      .find(
        (character) => character.position.x === x && character.position.y === y,
      );

    if (
      !nextTile ||
      nextTile.item ||
      nextTile.workshop ||
      nextTile.harvestPoint ||
      characterFound?.health > 0 ||
      nextTile.container
    ) {
      return true;
    }
    let nextTileSides = nextTile.sides || [false, false, false, false];
    if (currentTileSides.length === 1) {
      currentTileSides = [true, true, true, true];
    }
    if (nextTileSides.length === 1) {
      nextTileSides = [true, true, true, true];
    }

    return this._isSideBlocked(direction, currentTileSides, nextTileSides);
  }

  private _isSideBlocked(
    direction: Looking,
    currentTileSides: SidesTiles,
    nextTileSides: SidesTiles,
  ): boolean {
    if (direction === 'right') {
      return currentTileSides[1] || nextTileSides[3];
    } else if (direction === 'left') {
      return currentTileSides[3] || nextTileSides[1];
    } else if (direction === 'up') {
      return currentTileSides[0] || nextTileSides[2];
    } else if (direction === 'down') {
      return currentTileSides[2] || nextTileSides[0];
    }
  }
}
