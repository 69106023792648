import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { AttackKind } from '../../types/attack-kind.type';
import { FightDmgService } from '../fight-dmg/fight-dmg.service';
import { FightHitService } from '../fight-hit/fight-hit.service';

@Injectable({
  providedIn: 'root',
})
export class FightManagerService {
  constructor(
    private readonly _fightDmgService: FightDmgService,
    private readonly _fightHitService: FightHitService,
    private readonly _store: Store,
  ) {}

  // Argument : Attaquant, touche d'attaque
  // Résultat : Attaque le personnage en face de l'attaquant
  public attackCharacter(
    attacker: Character,
    target: Character,
    attack: AttackKind,
  ): void {
    if (!target) {
      return;
    }
    if (!target.isPlayer) {
      this._store.dispatch(
        CharactersActions.setState({
          idCharacter: target.idCharacter,
          state: 'fight',
        }),
      );
    }
    const hit = this._fightHitService.tryToHit(attacker, target, attack);
    this._fightDmgService.dealDmg(attacker, target, attack, hit);
  }
}
