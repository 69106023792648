import { HttpErrorResponse } from '@angular/common/http';
import { Language } from '@t12/common/user/types/language.type';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';

export const USER_FEATURE_KEY = 'user';

export interface UserSettings {
  music: boolean;
  noises: boolean;
}

export interface UserState {
  id?: number;
  settings: UserSettings;
  email: string;
  group: string;
  loadingStatus: LoadingStatus;
  createdAt?: Date;
  updatedAt?: Date;
  lastConnexionAt?: Date;
  exp?: number;
  iat?: number;
  error?: HttpErrorResponse;
  language?: Language;
}

export const initialUserState: UserState = {
  email: '',
  settings: {
    music: false,
    noises: false,
  },
  loadingStatus: LoadingStatus.NOT_LOADED,
  group: 'user',
  language: 'fr',
};
