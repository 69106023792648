import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { Recipe } from '@t12/common/job/interfaces/recipe.interface';
import { dedupe } from '@t12/common/utils/constants/dedupe.constant';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { JobActions } from '@t12/store/job/actions/job.actions';
import {
  getCurrentPageRecipe,
  getMaxPageRecipes,
  getRecipes,
} from '@t12/store/job/selectors/job.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { BehaviorSubject, take } from 'rxjs';
import { nbRecipesPage } from '../../constants/nb-recipes-page.constant';
import { JobManagerService } from '../job-manager/job-manager.service';
import { RecipeDbService } from '../recipe-db/recipe-db.service';

@Injectable({
  providedIn: 'root',
})
export class RecipesManagerService {
  public activeFilter: BehaviorSubject<ItemType | EquipmentSlot> =
    new BehaviorSubject(null);

  constructor(
    private readonly _jobService: JobManagerService,
    private readonly _itemDepositService: ItemDepositService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {}

  get getActiveFilter(): ItemType | EquipmentSlot {
    return this.activeFilter.value;
  }

  public setDisplayedRecipeItems(): void {
    const recipes = this._utilsService.getSelect(getRecipes);
    const currentPage = this._utilsService.getSelect(getCurrentPageRecipe) - 1;
    let recipesFilters: Recipe[];
    let recipesDisplayed: Recipe[];

    const filter = this.activeFilter.value;

    if (filter) {
      recipesFilters = recipes.filter(
        (recipe) =>
          recipe.products[0].slot === filter ||
          recipe.products[0].type === filter,
      );
    }

    if (recipesFilters.length === 0) return;

    recipesDisplayed = recipesFilters.slice(
      currentPage * nbRecipesPage,
      (currentPage + 1) * nbRecipesPage,
    );

    this._store.dispatch(
      JobActions.setDisplayedRecipes({ recipes: recipesDisplayed }),
    );

    this._store.dispatch(
      JobActions.setMaxPage({
        maxPage: Math.ceil(recipesFilters.length / nbRecipesPage),
      }),
    );
  }

  public incPagination(inc: 1 | -1): void {
    const currentPage = this._utilsService.getSelect(getCurrentPageRecipe);
    const maxPage = this._utilsService.getSelect(getMaxPageRecipes);
    if (currentPage + inc > maxPage || currentPage + inc < 1) return;

    this._store.dispatch(
      JobActions.setCurrentPage({ page: currentPage + inc }),
    );
    this.setDisplayedRecipeItems();
  }

  public getFilters(recipes: Recipe[]): (EquipmentSlot | ItemType)[] {
    const filters = dedupe(
      recipes.map(
        (recipe) => recipe.products[0].slot ?? recipe.products[0].type,
      ),
    ).sort();
    this.activeFilter.next(filters[0]);
    return filters;
  }

  public setActiveFilter(filter: ItemType): void {
    this.activeFilter.next(filter);
    this.setDisplayedRecipeItems();
  }

  public addRecipe(item: Item): void {
    const { code: itemCode, use } = item;
    const learn: any = use.learn;

    const playerJobXp = this._jobService.getJob(learn.jobCode)?.xp;

    if (playerJobXp === undefined) {
      this._notificationService.addNotification(
        'error',
        `Vous n'avez pas le métier correspondant!`,
      );
      return;
    }
    const { requiredXp } = learn;
    if (playerJobXp < requiredXp) {
      this._notificationService.addNotification(
        'error',
        `Expérience du métier trop bas! ${playerJobXp}<${requiredXp}XP`,
      );
      return;
    }
    this._recipeDb
      .learnRecipe(itemCode)
      .pipe(take(1))
      .subscribe((itemRecipe: Item) => {
        if (!itemRecipe) {
          this._notificationService.addNotification(
            'error',
            `Vous connaissez déjà cette recette`,
          );
          return;
        }

        this._notificationService.addNotification(
          'job',
          `Nouvelle recette apprise: ${itemRecipe.name}`,
        );
        this._itemDepositService.removeItemFrom(
          ContainerKind.Inventory,
          itemCode,
        );
      });
  }
}
