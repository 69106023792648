import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { defaultSpawnPositionConstant } from '@t12/common/player/constants/default-spawn-position.constant';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getPlayer } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { PlayerDbService } from '../player-db/player-db.service';
import { PlayerManagerService } from '../player-manager/player-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerDeathService {
  constructor(
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _chatService: ChatManagerService,
    private readonly _playerDb: PlayerDbService,
    private readonly _playerService: PlayerManagerService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : ------
  // Résultat : Pénalise le joueur pour être mort
  public playerDeath(): void {
    const player = this._utils.getSelect(getPlayer);
    const goldLoose = -Math.ceil(player.gold / 2);
    const xpLoose = -Math.ceil(player.xp / 2);
    this._playerService.updateInfosPlayerCharacter('gold', goldLoose); // TODO Allow multiple update in one
    this._playerService.updateInfosPlayerCharacter('xp', xpLoose);
    this._chatService.addChatLog(
      'fight',
      player.name,
      `est mort et a perdu  ${goldLoose} OR et ${xpLoose} points d'expériences`,
      ChatLogKind.Malus,
    );
    this._playerDb.playerDeath();

    setTimeout(() => {
      const setHealthAction = {
        idCharacter: 0,
        health: maxHealth(player.stats.con),
      };
      this._store.dispatch(CharactersActions.setHealth(setHealthAction));

      const setManaAction = { idCharacter: 0, mana: player.stats.int * 3 + 10 };
      this._store.dispatch(CharactersActions.setMana(setManaAction));

      this._store.dispatch(
        CharactersActions.setLooking({ idCharacter: 0, looking: 'down' }),
      );
      this._activeTargetService.setActiveTarget(null);
      const {
        worldCode,
        positionX: x,
        positionY: y,
      } = defaultSpawnPositionConstant;
      const warp: Warp = { worldCode, position: { x, y } };
      this._worldService.initWarp(warp);
      this._worldService.loadMap();
    }, 4000);
  }
}
