import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { RewardFight } from '@t12/common/fight/interfaces/reward-fight.interface';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { PlayerManagerService } from '@t12/player/services/player-manager/player-manager.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { getPlayerName } from '@t12/store/characters/selectors/characters.selectors';
import { QuestActions } from '@t12/store/quests/actions/quest.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class FightRewardService {
  constructor(
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerService: PlayerManagerService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : Personnage vaincu
  // Résultat : Récompense le joueur en attribuant l'or et l'xp du personnage battu
  public rewardFight(enemy: Monster | NPC, reward: RewardFight): void {
    const playerName = this._utils.getSelect(getPlayerName);
    const { xp, gold } = reward;

    if (xp > 0 && gold > 0) {
      this._notificationService.addNotification(
        'item',
        `+${gold} OR et +${xp} XP`,
      );
      this._chatService.addChatLog(
        'fight',
        playerName,
        `a gagné ${gold} OR et ${xp} points d'expériences`,
        ChatLogKind.Bonus,
      );
      this._playerService.updateInfosPlayerCharacter('gold', gold);
      this._playerService.updateInfosPlayerCharacter('xp', xp);
    } else if (gold < 0) {
      this._notificationService.addNotification('crime', `-${gold} OR`);
      this._chatService.addChatLog(
        'fight',
        playerName,
        `a commit un crime et à du payer ${gold} OR.`,
        ChatLogKind.Malus,
      );
      this._playerService.updateInfosPlayerCharacter('gold', gold);
    } else {
      this._notificationService.addNotification('item', `+${gold} OR`);
      this._chatService.addChatLog(
        'fight',
        playerName,
        `a gagné ${gold} OR`,
        ChatLogKind.Bonus,
      );
      this._playerService.updateInfosPlayerCharacter('gold', gold);
    }

    if (enemy.code) {
      const activeGoalQuest = this._questGoalsService.findGoalFromPlayerQuest(
        enemy.code,
        'kill',
      );
      if (activeGoalQuest)
        this._store.dispatch(
          QuestActions.updateGoal({ goal: activeGoalQuest, amount: 1 }),
        );
    }
  }
}
