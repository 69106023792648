import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { QuestDbService } from '@t12/quest/services/quest-db/quest-db.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { QuestActions } from '@t12/store/quests/actions/quest.actions';
import { UserActions } from '@t12/store/user/actions/user.actions';
import { LocalService } from '@t12/utils/services/local/local.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { catchError, map, switchMap, of, take } from 'rxjs';
import { HotkeyActions } from '../../hotkey/actions/hotkey.actions';
import { CharactersActions } from '../actions/characters.actions';

dayjs.locale('fr');

@Injectable()
export class CharactersEffects {
  private _loadPlayerCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayer),
      switchMap(() =>
        this._playerDbService.getPlayerCharacter$().pipe(
          take(1),
          map((player) => CharactersActions.loadPlayerSuccess({ player })),
          catchError((error) =>
            of(CharactersActions.loadPlayerFailed({ error })),
          ),
        ),
      ),
    ),
  );

  private _loadPlayerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayerSuccess),
      switchMap(({ player }) => {
        this._store.dispatch(
          CharactersActions.setLooking({
            idCharacter: 0,
            looking: player.looking,
          }),
        );
        const hotkeys = this._localService.getJsonValue(`hotkeys${player.id}`);
        if (hotkeys)
          this._store.dispatch(HotkeyActions.setHotkeys({ hotkeys }));

        const date = dayjs(player.lastConnexion);
        const hour = date.format('HH:mm');
        const day = date.format('DD MMMM YYYY');

        this._chatManagerService.addChatLog(
          'chat',
          '',
          `Dernière connexion à ${hour}, le ${day}.`,
          ChatLogKind.Log,
        );

        if (!player.lastConnexion)
          this._store.dispatch(
            HudDisplayActions.showHud({ name: 'presentation' }),
          );

        this._worldService.loadMap();

        return of(CharactersActions.initPlayerSuccess());
      }),
    ),
  );

  private _initPlayerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.initPlayerSuccess),
      switchMap(() =>
        this._questDbService.getQuestsPlayer().pipe(
          take(1),
          map((questsInfos) => QuestActions.setQuests({ questsInfos })),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  private _setCharacters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      switchMap(({ characters }) => {
        for (const character of characters) {
          if (character.state !== 'fight' && !character.static) {
            this._characterService.createPatrolInterval(character.idCharacter);
          }
          const activeGoalQuest =
            this._questGoalsService.findGoalFromPlayerQuest(
              character.code,
              'exploration',
            );
          if (!activeGoalQuest) continue;

          if (activeGoalQuest.amount !== activeGoalQuest.amountTotal) {
            this._store.dispatch(
              QuestActions.updateGoal({ goal: activeGoalQuest, amount: 1 }),
            );
            this._questGoalsService.addNotificationQuestGoal(activeGoalQuest);
          }
        }

        return of(CharactersActions.setCharactersSuccess());
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _characterService: CharacterManagerService,
    private readonly _chatManagerService: ChatManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _questDbService: QuestDbService,
    private readonly _localService: LocalService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _store: Store,
  ) {}
}
