import { Bank } from '@t12/common/bank/interfaces/bank.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const BANK_FEATURE_KEY = 'bank';

export interface BankState extends Bank {
  displayedItems?: Item[];
  currentPage?: number;
  maxPage?: number;
}

export const initialBankState: BankState = {
  playerId: undefined,
  lvl: 1,
  gold: 0,
  items: [],
  currentPage: 1,
  maxPage: 10,
};
