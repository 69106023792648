import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { DialogQuestService } from '@t12/dialog/services/dialog-quest/dialog-quest.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { DialogActions } from '@t12/store/dialog/actions/dialog.actions';
import { QuestActions } from '@t12/store/quests/actions/quest.actions';
import { take } from 'rxjs';
import { QuestDbService } from '../quest-db/quest-db.service';
import { QuestGoalsService } from '../quest-goals/quest-goals.service';
import { QuestsManagerService } from '../quests-manager/quests-manager.service';

@Injectable({
  providedIn: 'root',
})
export class QuestTransitionService {
  constructor(
    private readonly _dialogQuestService: DialogQuestService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _questService: QuestsManagerService,
    private readonly _questDbService: QuestDbService,
    private readonly _store: Store,
  ) {}

  // TODO A faire côté back sans passer par initQuest
  public checkForceNextQuest(target: NPC | Monster) {
    const quest = this._questGoalsService.findCharacterInGoalQuest(target.code);
    if (!quest) return;

    const [goal] = quest.goals;
    if (quest.goals[0].nextQuest) {
      this._store.dispatch(
        DialogActions.setCharacterDialog({ character: target }),
      );
      this._dialogQuestService.getDialogQuest(
        goal.nextQuest.code,
        goal.entityCode,
      );
      this._questService.rewardQuest(quest, target.idCharacter);

      this._questDbService
        .initQuestPlayer(goal.nextQuest.code, goal.nextQuest.npcCode)
        .pipe(take(1))
        .subscribe((questInfos) => {
          this._notificationService.addNotification(
            'quest',
            `Nouvelle quête ajoutée.`,
          );
          this._questService.removeQuest(questInfos.requiredQuest);
          this._store.dispatch(QuestActions.addQuest({ questInfos }));
          this._questGoalsService.updateCollectGoals();
        });
    }
  }
}
