import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QuestActions } from '@t12/store/quests/actions/quest.actions';
import { getActiveGoal } from '@t12/store/quests/selectors/quest.selectors';
import { tap } from 'rxjs';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable()
export class QuestEffects {
  private updateGoal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.updateGoal),
        tap(() => {
          const activeGoal = this._utils.getSelect(getActiveGoal);
          if (activeGoal?.kind !== 'kill') return;

          this._questGoalsService.addNotificationQuestGoal(activeGoal);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _utils: UtilsService,
  ) {}
}
