import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { BANK_FEATURE_KEY } from '@t12/store/bank';
import { BankReducer } from '@t12/store/bank/reducers/bank.reducer';
import { PLAYER_FEATURE_KEY } from '@t12/store/characters';
import { CharactersEffects } from '@t12/store/characters/effects/characters.effects';
import { CharactersReducer } from '@t12/store/characters/reducers/characters.reducer';
import { CHAT_FEATURE_KEY } from '@t12/store/chat';
import { ChatReducer } from '@t12/store/chat/reducers/chat.reducer';
import { CONTAINER_FEATURE_KEY } from '@t12/store/container';
import { ContainerReducer } from '@t12/store/container/reducers/container.reducer';
import { CREATION_PLAYER_FEATURE_KEY } from '@t12/store/creation-player';
import { CreationPlayerEffects } from '@t12/store/creation-player/effects/creation-player.effects';
import { CreationPlayerReducer } from '@t12/store/creation-player/reducers/creation-player.reducer';
import { DIALOG_FEATURE_KEY } from '@t12/store/dialog';
import { DialogEffects } from '@t12/store/dialog/effects/dialog.effects';
import { DialogReducer } from '@t12/store/dialog/reducers/dialog.reducer';
import { HOTKEY_FEATURE_KEY } from '@t12/store/hotkey';
import { HotkeyReducer } from '@t12/store/hotkey/reducers/hotkey.reducer';
import { HUD_DISPLAY_FEATURE_KEY } from '@t12/store/hud-display';
import { HudDisplayEffects } from '@t12/store/hud-display/effects/hud-display.effects';
import { HudDisplayReducer } from '@t12/store/hud-display/reducers/hud-display.reducer';
import { JOB_FEATURE_KEY } from '@t12/store/job';
import { JobReducer } from '@t12/store/job/reducers/job.reducer';
import { QUEST_FEATURE_KEY } from '@t12/store/quests';
import { QuestEffects } from '@t12/store/quests/effects/quest.effects';
import { QuestReducer } from '@t12/store/quests/reducers/quest.reducer';
import { SHOP_FEATURE_KEY } from '@t12/store/shop';
import { ShopReducer } from '@t12/store/shop/reducers/shop.reducer';
import { USER_FEATURE_KEY } from '@t12/store/user';
import { UserEffects } from '@t12/store/user/effects/user.effects';
import { UserReducer } from '@t12/store/user/reducers/user.reducer';
import { routes } from './app-routing.module';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideStore(
      {
        [USER_FEATURE_KEY]: UserReducer,
        [PLAYER_FEATURE_KEY]: CharactersReducer,
        [HOTKEY_FEATURE_KEY]: HotkeyReducer,
        [HUD_DISPLAY_FEATURE_KEY]: HudDisplayReducer,
        [DIALOG_FEATURE_KEY]: DialogReducer,
        [CREATION_PLAYER_FEATURE_KEY]: CreationPlayerReducer,
        [SHOP_FEATURE_KEY]: ShopReducer,
        [CHAT_FEATURE_KEY]: ChatReducer,
        [QUEST_FEATURE_KEY]: QuestReducer,
        [BANK_FEATURE_KEY]: BankReducer,
        [JOB_FEATURE_KEY]: JobReducer,
        [CONTAINER_FEATURE_KEY]: ContainerReducer,
      },
      {
        runtimeChecks: {
          strictActionTypeUniqueness: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    provideEffects([
      UserEffects,
      CharactersEffects,
      HudDisplayEffects,
      DialogEffects,
      CreationPlayerEffects,
      QuestEffects,
    ]),
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en'],
        defaultLang: 'fr',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
};
