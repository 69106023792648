import { Injectable } from '@angular/core';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { JobLearn } from '@t12/common/job/interfaces/learn-knowlegde.interface';
import { isJobLearn } from '@t12/common/utils/knowledge/functions/isJobLearn.function';
import { isRecipeLearn } from '@t12/common/utils/knowledge/functions/isRecipeLearn.function';
import { JobManagerService } from '../job-manager/job-manager.service';
import { RecipesManagerService } from '../recipes-manager/recipes-manager.service';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeManagerService {
  constructor(
    private readonly _jobService: JobManagerService,
    private readonly _recipeService: RecipesManagerService,
  ) {}

  learnKnowledge(item: Item): void {
    const { code: itemCode } = item;
    const { learn } = item.use;
    if (isJobLearn(learn)) {
      const { code, kind } = learn as JobLearn;
      this._jobService.addJob(itemCode, code, kind);
    } else if (isRecipeLearn(learn)) {
      this._recipeService.addRecipe(item);
    }
  }
}
