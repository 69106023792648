import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { Recipe } from '@t12/common/job/interfaces/recipe.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JobActions = createActionGroup({
  source: 'JOB',
  events: {
    Init: emptyProps(),
    'Set active job index': props<{ index: number }>(),
    'Set active job kind': props<{ kind: JobKind }>(),
    'Set active tab': props<{ tab: TabJob }>(),
    'Set recipes': props<{ recipes: Recipe[] }>(),
    'Set displayed recipes': props<{ recipes: Recipe[] }>(),
    'Set current page': props<{ page: number }>(),
    'Set max page': props<{ maxPage: number }>(),
    'Set workshop': props<{ workshop: Workshop }>(),
  },
});
