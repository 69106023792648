import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { UserSettings } from '@t12/store/user';
import { getUserSettings } from '@t12/store/user/selectors/user.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Howl } from 'howler';
import { takeUntil, Subject } from 'rxjs';
import { VOLUME_MUSIC, VOLUME_NOISES } from '../../constants/volume.constant';

@Injectable({
  providedIn: 'root',
})
export class AudioManagerService implements OnDestroy {
  private _backgroundSound: Howl;
  private _settings: UserSettings = {
    music: false,
    noises: false,
  };
  private _isMusicPlaying = false;
  private _musicName: string;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {
    this._store
      .select(getUserSettings)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this._settings = settings;
      });
    document.addEventListener('visibilitychange', (ev) => {
      this._onChangeVisibility(ev);
    });
  }

  // Arguments : Nom de la musique, forcer le lancement de la musique
  // Résultat : Execute une musique d'ambiance si celle-ci n'est pas déjà lancée
  public playBackgroundMusic(newMusicName: string): void {
    const canPlayMusic = this._settings.music && !!newMusicName;
    const newMusic = this._musicName !== newMusicName;

    if (canPlayMusic && (!this._isMusicPlaying || newMusic)) {
      if (newMusic) {
        this.changeMusic(newMusicName);
      }
      this._isMusicPlaying = true;
      this._backgroundSound.play();
    }
  }

  // Arguments : Nom de la nouvelle musique
  // Résultat : Stop la musique initiale et charge la prochaine
  public changeMusic(newMusicName: string): void {
    this.stopBackgroundMusic();
    if (this._backgroundSound) {
      this._backgroundSound.unload();
    }
    this._musicName = newMusicName;
    this._backgroundSound = new Howl({
      src: [`assets/sounds/musics/${this._musicName}.mp3`],
      autoplay: true,
      loop: true,
      volume: VOLUME_MUSIC,
    });
  }

  // Arguments : Dossier contenant le son, nom du son, type de fichier, volume
  // Résultat : Active un son précis
  public playSound(
    folder: string,
    name: string,
    ext = 'mp3',
    volume = VOLUME_NOISES,
  ): void {
    if (this._settings.noises) {
      const sound = new Howl({
        src: [`assets/sounds/${folder}/${name}.${ext}`],
        volume,
      });
      sound.play();
    }
  }

  // Argument : Type de l'équipement
  // Résultat : Joue un son différent selon l'équipement choisis.
  public playSoundEquip(slot: EquipmentSlot): void {
    if (this._settings.noises && slot) {
      this.playSound('interfaces/equipment', slot, 'mp3', VOLUME_NOISES);
    }
  }

  // Argument : ------
  // Résultat : Met en pause la musique
  public stopBackgroundMusic(): void {
    if (this._backgroundSound) {
      this._backgroundSound.pause();
      this._isMusicPlaying = false;
    }
  }

  // Argument : ------
  // Résultat : Met en pause la musique et bloque tous les sons
  public muteAndPause(): void {
    this._settings = {
      music: false,
      noises: false,
    };
    this.stopBackgroundMusic();
  }

  // Argument : Event
  // Résultat : Met en pause ou relance la musique si le focus de la page change
  private _onChangeVisibility(evt): void {
    const evtMap = {
      focus: false,
      focusin: false,
      pageshow: false,
      blur: true,
      focusout: true,
      pagehide: true,
    };

    const windowHidden =
      evt.type in evtMap ? evtMap[evt.type] : document.hidden;

    if (windowHidden) {
      this.muteAndPause();
    } else {
      this._settings = this._utilsService.getSelect(getUserSettings);
      if (this._musicName && this._settings.music) {
        this.playBackgroundMusic(this._musicName);
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
