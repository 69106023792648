import { Injectable } from '@angular/core';
import { environment } from '@t12/environment';
import { SocketIoConfig, Socket } from 'ngx-socket-io';

const config: SocketIoConfig = { url: environment(``).ws, options: {} };

@Injectable({
  providedIn: 'root',
})
export class SocketService extends Socket {
  constructor() {
    super(config);
  }
}
