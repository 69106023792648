import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { BankState } from '@t12/store/bank';

export const BankActions = createActionGroup({
  source: 'BANK',
  events: {
    Init: emptyProps(),
    'Set bank': props<{ bank: BankState }>(),
    'Add gold': props<{ amountGold: number }>(),
    'Set items': props<{ items: Item[] }>(),
    'Set current page': props<{ page: number }>(),
  },
});
