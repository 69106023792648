import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Looking } from '@t12/common/characters/types/looking.type';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { FightManagerService } from '@t12/fight/services/fight-manager/fight-manager.service';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getCharacters } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { take } from 'rxjs';
import { CharacterMovementService } from '../character-movement/character-movement.service';
import { TimersManagerService } from '../timers-bot/timers-manager.service';
import { SpriteAnimationsX } from '@t12/characters/enums/sprite-animations-x.enum';

@Injectable({
  providedIn: 'root',
})
export class CharacterManagerService {
  private _direction: Looking[] = ['up', 'right', 'down', 'left'];

  constructor(
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _audioService: AudioManagerService,
    private readonly _characterMovementService: CharacterMovementService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _fightService: FightManagerService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _timerService: TimersManagerService,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : Personne à vérifier
  // Résultat : Retourne la position {x,y} de la tuile face au personnage
  public getPositionTileInFront(character: Character): {
    x: number;
    y: number;
  } {
    const { x: positionX, y: positionY } = character.position;
    const directionMap = {
      right: { x: positionX + 1, y: positionY },
      up: { x: positionX, y: positionY - 1 },
      down: { x: positionX, y: positionY + 1 },
      left: { x: positionX - 1, y: positionY },
    };

    return directionMap[character.looking];
  }

  // Argument : Attaquant à animer, le type d'attaque
  // Résultat : Anime le personnage selon le type d'attaque
  public attackAnimation(attackerId: number, attack: AttackKind): void {
    const attacker = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === attackerId);

    if (!attacker || attacker.health <= 0) return;

    if (attack === 'magic' && !this._canUseMagic(attacker)) return;

    const { idCharacter } = attacker;
    const bonusSpeedAttack = 0;
    const delayAttack = 300 - bonusSpeedAttack;

    this._store.dispatch(
      CharactersActions.setCanMove({ idCharacter, canMove: false }),
    );

    setTimeout(() => {
      this._firstAnimationAttack(attacker.idCharacter, attack);
      setTimeout(() => {
        this._store.dispatch(
          CharactersActions.setSpriteX({
            idCharacter,
            spriteX:
              attack === 'physic'
                ? SpriteAnimationsX.SECOND_PHYSICAL_ATTACK_X
                : SpriteAnimationsX.SECOND_MAGICAL_ATTACK_X,
          }),
        );
        setTimeout(() => {
          this._lastAnimationAttack(attacker.idCharacter, attack);
          setTimeout(() => {
            this._store.dispatch(
              CharactersActions.setSpriteX({
                idCharacter,
                spriteX: SpriteAnimationsX.IDLE_STEP_X,
              }),
            );
            this._store.dispatch(
              CharactersActions.setCanMove({ idCharacter, canMove: true }),
            );
          }, 276);
        }, 100);
      }, delayAttack);
    });
  }

  // Argument : Attaquant et la cible
  // Résultat : Ajoute un interval de patrouille pour un NPC
  public createPatrolInterval(idCharacter: number): void {
    const timersMove = this._timerService.getTimersMove();

    if (!timersMove.has(idCharacter)) {
      const randTimeMove = Math.floor(Math.random() * 4000 + 4000);
      timersMove.set(
        idCharacter,
        window.setInterval(
          () => this._moveBotRandom(idCharacter),
          randTimeMove,
        ),
      );
    }
  }

  // Argument : Attaquant et la cible
  // Résultat : Crée un interval de combat pour un NPC
  public createTurnFightInterval(attacker, target): void {
    const timersFight = this._timerService.getTimersFight();
    this._store.dispatch(
      CharactersActions.setState({
        idCharacter: target.idCharacter,
        state: 'fight',
      }),
    );
    if (!timersFight.has(target.idCharacter) && target.health > 0) {
      timersFight.set(
        target.idCharacter,
        window.setInterval(() => this._turnFightNPC(target, attacker), 1000),
      );
    }
  }

  // Argument : Personnage, personnage cible
  // Résultat : Tourne le personnage cible face au personnage
  public setFaceTo(character1: Character, character2: Character): void {
    const position1 = character1.position;
    const { idCharacter, position: position2 } = character2;

    if (position1 && position2) {
      if (position1.y < position2.y) {
        this._store.dispatch(
          CharactersActions.setLooking({ idCharacter, looking: 'up' }),
        );
      } else if (position1.y > position2.y) {
        this._store.dispatch(
          CharactersActions.setLooking({ idCharacter, looking: 'down' }),
        );
      } else if (position1.x < position2.x) {
        this._store.dispatch(
          CharactersActions.setLooking({ idCharacter, looking: 'left' }),
        );
      } else if (position1.x > position2.x) {
        this._store.dispatch(
          CharactersActions.setLooking({ idCharacter, looking: 'right' }),
        );
      }
    }
  }

  // Argument : Id personnage, type d'attack
  // Résultat : Fait la première frame d'anaimtion d'attack
  private _firstAnimationAttack(idCharacter: number, attack: AttackKind): void {
    const attacker = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === idCharacter);

    if (attack === 'physic') {
      this._store.dispatch(
        CharactersActions.setSpriteX({
          idCharacter,
          spriteX: SpriteAnimationsX.FIRST_PHYSICAL_ATTACK_X,
        }),
      );
    } else if (attack === 'magic') {
      const costMana = Math.max(
        Math.floor((attacker.stats.int * 3 + 10) * 0.06),
        2,
      );
      this._store.dispatch(
        CharactersActions.addMana({ idCharacter, mana: -costMana }),
      );
      this._store.dispatch(
        CharactersActions.setSpriteX({
          idCharacter,
          spriteX: SpriteAnimationsX.FIRST_MAGICAL_ATTACK_X,
        }),
      );

      if (attacker.isPlayer)
        this._playerDbService.updatePlayer().pipe(take(1)).subscribe();
    }
  }

  private _playAttackSound(attack): void {
    const soundType = attack === 'physic' ? 'hit' : 'spell';
    const rand = Math.floor(Math.random() * (attack === 'physic' ? 8 : 2));
    this._audioService.playSound('impacts', `${soundType}_${rand}`);
  }

  private _setCharacterInFight(attacker, target): void {
    this._activeTargetService.setActiveTarget(target);
    this.setFaceTo(attacker, target);
    this._timerService.stopTimerMoveByID(target.idCharacter);
    this._store.dispatch(
      CharactersActions.setState({
        idCharacter: target.idCharacter,
        state: 'fight',
      }),
    );
    this.createTurnFightInterval(attacker, target);
  }

  private _lastAnimationAttack(attackerId: number, attack: AttackKind): void {
    this._playAttackSound(attack);
    this._store.dispatch(
      CharactersActions.setSpriteX({
        idCharacter: attackerId,
        spriteX:
          attack === 'physic'
            ? SpriteAnimationsX.LAST_PHYSICAL_ATTACK_X
            : SpriteAnimationsX.LAST_MAGICAL_ATTACK_X,
      }),
    );
    const attacker = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === attackerId);
    const { x, y } = this.getPositionTileInFront(attacker);
    let target = this._utils
      .getSelect(getCharacters)
      .find(({ position }) => position.x === x && position.y === y);
    const isAttackerAndTargetAlive = attacker?.health > 0 && target?.health > 0;

    if (!target?.isPlayer && isAttackerAndTargetAlive) {
      this._fightService.attackCharacter(attacker, target, attack);
      target = this._utils
        .getSelect(getCharacters)
        .find(({ position }) => position.x === x && position.y === y);
      this._setCharacterInFight(attacker, target);
    } else if (target) {
      this._fightService.attackCharacter(attacker, target, attack);
    }
  }

  // Argument : Attaquant NPC et la cible
  // Résultat : Prépare le combat et son déroulement
  private _turnFightNPC(attacker: NPC | Monster, target: Character): void {
    const characters = this._utils.getSelect(getCharacters);
    [target, attacker] = [target, attacker].map((obj) =>
      characters.find((character) => character.idCharacter === obj.idCharacter),
    );

    this.setFaceTo(target, attacker);
    const { x, y } = this.getPositionTileInFront(attacker);
    const targetIsAlive = target.health > 0;
    const { position } = target;

    // Attaque le joueur
    if (y === position.y && x === position.x && targetIsAlive) {
      this.attackAnimation(
        attacker.idCharacter,
        this._chooseAttackNPC(attacker),
      );
    } else if (targetIsAlive) {
      // Poursuit le joueur
      if (attacker.canMove && this._worldService.canStepOnTile(attacker)) {
        this._characterMovementService.moveForward(attacker.idCharacter);
      }
    } else if (!targetIsAlive) {
      // Joueur/Cible mort
      this._store.dispatch(
        CharactersActions.setState({
          idCharacter: attacker.idCharacter,
          state: 'passive',
        }),
      );
      this._timerService.stopTimerFightByID(attacker.idCharacter);
      this.createPatrolInterval(attacker.idCharacter);
    }
  }

  // Argument : Attaquant NPC
  // Résultat : Choisis le type de la prochaine attaque du NPC, physique ou magique (max 40% chance magic)
  private _chooseAttackNPC(attacker): AttackKind {
    const roll = Math.floor(Math.random() * 100);
    const useMagic = Math.min(40, 20 + 0.3 * attacker.stats.int);

    return this._canUseMagic(attacker) && roll <= useMagic ? 'magic' : 'physic';
  }

  // Argument : Personnage à vérifier
  // Résultat : Vérifie que le personnage a assez de mana pour faire une attaque magique.
  private _canUseMagic(character: Character): boolean {
    if (!character) {
      return false;
    }
    const costMana = Math.max(
      Math.floor((character.stats.int * 3 + 10) * 0.06),
      2,
    );
    const enoughMana = character.mana >= costMana;

    if (!enoughMana && character.isPlayer) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas assez de mana!",
      );
    }

    return enoughMana;
  }

  // Argument : Personnage à déplacer
  // Résultat : Déplace de manière aléatoire un personnage ordinateur
  private _moveBotRandom(IdCharacter: number): void {
    const randomIndex = Math.floor(Math.random() * this._direction.length);
    let characterFound = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === IdCharacter);
    this._store.dispatch(
      CharactersActions.setLooking({
        idCharacter: characterFound.idCharacter,
        looking: this._direction[randomIndex],
      }),
    );
    characterFound = this._utils
      .getSelect(getCharacters)
      .find((character) => character.idCharacter === IdCharacter);

    if (
      characterFound.canMove &&
      this._worldService.canStepOnTile(characterFound)
    ) {
      this._characterMovementService.moveForward(characterFound.idCharacter);
    }
  }
}
