import { Injectable } from '@angular/core';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { RewardFight } from '@t12/common/fight/interfaces/reward-fight.interface';
import { ContainerManagerService } from '@t12/container/services/container/container-manager.service';
import { FightRewardService } from '@t12/fight/services/fight-reward/fight-reward.service';
import { QuestTransitionService } from '@t12/quest/services/quest-transition/quest-transition.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerKillService {
  constructor(
    private readonly _fightRewardService: FightRewardService,
    private readonly _containerService: ContainerManagerService,
    private readonly _questTransitionService: QuestTransitionService,
  ) {}

  public playerKillCharacter(
    target: NPC | Monster,
    rewardFight: RewardFight,
  ): void {
    this._fightRewardService.rewardFight(target, rewardFight);

    if (rewardFight.container) {
      const { container } = rewardFight;
      this._containerService.placeContainerOnTile(target.position, container);
    }

    this._questTransitionService.checkForceNextQuest(target);
  }
}
