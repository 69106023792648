import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { CharacterMovementService } from '@t12/characters/services/character-movement/character-movement.service';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Looking } from '@t12/common/characters/types/looking.type';
import { ContainerManagerService } from '@t12/container/services/container/container-manager.service';
import { DialogInitService } from '@t12/dialog/services/dialog-init/dialog-init.service';
import { DialogManagerService } from '@t12/dialog/services/dialog-manager/dialog-manager.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { InventoryPickService } from '@t12/inventory/services/inventory-pick/inventory-pick.service';
import { InventoryUseService } from '@t12/inventory/services/inventory-use/inventory-use.service';
import { HarvestPointManagerService } from '@t12/jobs/services/harvest-points/harvest-point-manager.service';
import { WorkshopManagerService } from '@t12/jobs/services/workshop-manager/workshop-manager.service';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { FullscreenManagerService } from '@t12/settings/services/fullscreen/fullscreen-manager.service';
import { ShopManagerService } from '@t12/shop/services/shop/shop-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import {
  getCharacters,
  getPlayer,
} from '@t12/store/characters/selectors/characters.selectors';
import { getContainerItems } from '@t12/store/container/selectors/container.selectors';
import { getDialogOptions } from '@t12/store/dialog/selectors/dialog.selectors';
import { HotkeyState } from '@t12/store/hotkey';
import { getHotkeys } from '@t12/store/hotkey/selectors/hotkey.selectors';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import {
  getAllHudDisplay,
  getHudChat,
  getHudContainer,
  getHudDialog,
} from '@t12/store/hud-display/selectors/hud-display.selectors';
import { getWorkshop } from '@t12/store/job/selectors/job.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { take } from 'rxjs';
import { attackKey, moveKey } from '../constants/key-bindings.constant';
import { actionKey } from '../types/action-key.type';

@Injectable({
  providedIn: 'root',
})
export class KeyHandlerService {
  private _intervalTickInput: NodeJS.Timeout;

  constructor(
    private readonly _characterMovementService: CharacterMovementService,
    private readonly _characterService: CharacterManagerService,
    private readonly _chatManagerService: ChatManagerService,
    private readonly _dialogInit: DialogInitService,
    private readonly _dialogManagerService: DialogManagerService,
    private readonly _fullscreenService: FullscreenManagerService,
    private readonly _harvestPointService: HarvestPointManagerService,
    private readonly _inventoryPickService: InventoryPickService,
    private readonly _inventoryUseService: InventoryUseService,
    private readonly _containerService: ContainerManagerService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _shopService: ShopManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
    private readonly _workshopService: WorkshopManagerService,
  ) {
    document.addEventListener('keydown', (event) => {
      const key = event.key.toLowerCase();
      const player = this._utils.getSelect(getPlayer);
      const allHudDisplay = this._utils.getSelect(getAllHudDisplay);

      // TODO ArrowUpChat
      // } else if (event.key === 'ArrowUp') {
      //   this.chatText = this.chatService.repeatLastMsg();

      // In-game
      if (player) {
        if (key === 'arrowup' && allHudDisplay.chat) {
          this._chatManagerService.repeatLastMsg();
        } else if (key === 'i' && !allHudDisplay.chat) {
          this._store.dispatch(
            HudDisplayActions.toggleHud({ name: 'inventory' }),
          );
        } else if (key === 'l' && !allHudDisplay.chat) {
          this._store.dispatch(HudDisplayActions.toggleHud({ name: 'quests' }));
          this._store.dispatch(
            HudDisplayActions.hideHud({ name: 'inventory' }),
          );
        } else if (key === 'c' && !allHudDisplay.chat) {
          if (!allHudDisplay.playerInfos && allHudDisplay.jobs) {
            this._store.dispatch(HudDisplayActions.hideHud({ name: 'jobs' }));
          } else {
            this._store.dispatch(
              HudDisplayActions.toggleHud({ name: 'playerInfos' }),
            );
          }
        } else if (key === 'escape') {
          this._closeAllHUD();
        } else if (key === 'y' && !allHudDisplay.chat) {
          this._store.dispatch(HudDisplayActions.showHud({ name: 'chat' }));
        } else {
          const action = this.identifyAction(key);
          if (action) {
            const interval = action[0] === 'hotkey' ? 3000 : 276;
            this.executeKey(action);

            if (!this._intervalTickInput && key !== ' ') {
              this._intervalTickInput = setInterval(
                () => this.executeKey(action),
                interval,
              );
            }
          }
        }
      }

      //Everywhere
      if (key === 'f11') {
        event.preventDefault();
        this._fullscreenService.switchFullscreen();
      }
    });

    document.addEventListener('keyup', ({ key }) => {
      const player = this._utils.getSelect(getPlayer);
      if (!player) return;

      this._clearTimer();

      const action = this.identifyAction(key.toLowerCase());
      if (action?.[0] === 'move') {
        this._playerDbService.updatePlayer().pipe(take(1)).subscribe();
      }
    });
  }

  // Argument : Touche de clavier
  // Résultat : Retourne l'action selon la touche saisie et la direction ou le type d'attaque
  // TODO Convert getActionKey en un map pour accéder directement à l'action type
  public identifyAction(
    key: string,
  ): [actionKey, string | HotkeyState] | [actionKey] | null {
    const direction = this._getActionByKey(moveKey, key);
    const attack = this._getActionByKey(attackKey, key);
    const hotkeys = this._utils.getSelect(getHotkeys);
    const hotkey =
      hotkeys.length > 0
        ? hotkeys.find((hotkeyItem) => hotkeyItem.key === key)
        : undefined;

    if (direction) {
      return ['move', direction];
    } else if (attack) {
      return ['attack', attack];
    } else if (key === ' ') {
      return ['interaction'];
    } else if (hotkey) {
      return ['hotkey', hotkey];
    }
    return null;
  }

  // Argument : Touche de clavier
  // Résultat : Execute une action selon la touche de clavier renseignée
  public executeKey(action: [actionKey, any] | [actionKey]): void {
    const player: Character = this._utils.getSelect(getPlayer);

    if (!this._canMakeAction() || !action) {
      return;
    }
    if (action[0] === 'move') {
      this._moveAction(action[1] as Looking);
    } else if (action[0] === 'attack') {
      this._characterService.attackAnimation(player.idCharacter, action[1]);
    } else if (action[0] === 'interaction') {
      this._interactionAction();
    } else if (action[0] === 'hotkey') {
      this._inventoryUseService.tryToUseItem(
        action[1].entity.code,
        action[1].entity.type,
      );
    }
  }

  // Argument : Touche de clavier
  // Résultat : Retourne la direction de déplacement ou le type d'attaque selon la touche saisie
  private _getActionByKey(keyBinds: any, key: string): string {
    return Object.keys(keyBinds).find((keyObj) => {
      if (keyBinds[keyObj].includes(key)) {
        return keyObj;
      }
    });
  }

  // Argument : ------
  // Résultat : Vérifie si le personnage peut faire une action
  private _canMakeAction(): boolean {
    const player = this._utils.getSelect(getPlayer);
    const isVisibleChat: boolean = this._utils.getSelect(getHudChat);

    return player.canMove && player.health > 0 && !isVisibleChat;
  }

  // Argument : Direction du joueur
  // Résultat : Fait déplacer le joueur
  private _moveAction(direction: Looking): void {
    const player = this._utils.getSelect(getPlayer);
    const hudState = this._utils.getSelect(getAllHudDisplay);
    const { idCharacter } = player;
    this._store.dispatch(
      CharactersActions.setLooking({
        idCharacter,
        looking: direction,
      }),
    );
    const isWorkshop = this._utils.getSelect(getWorkshop);
    if (isWorkshop) this._workshopService.closeWorkshop();

    if (this._worldService.canStepOnTile(this._utils.getSelect(getPlayer))) {
      if (hudState.dialog) {
        this._dialogInit.endDialog();
      }
      if (hudState.shop) {
        this._shopService.closeShop();
      }
      if (hudState.container) {
        this._store.dispatch(HudDisplayActions.hideHud({ name: 'container' }));
      }
      if (hudState.bank) {
        this._store.dispatch(HudDisplayActions.hideHud({ name: 'bank' }));
      }
      this._characterMovementService.moveForward(idCharacter);
    }
  }

  // Argument : ------
  // Résultat : Fait interagir le joueur avec la tuile qu'il regarde
  private _interactionAction(): void {
    const player = this._utils.getSelect(getPlayer);
    const { x, y } = this._characterService.getPositionTileInFront(player);
    const tile = this._worldService.getTile(y, x);
    if (!tile) return;

    const characters = this._utils.getSelect(getCharacters);
    const characterFound: NPC | Monster = characters.find(
      (character) => character.position.x === x && character.position.y === y,
    );

    const { item: tileItem, container: tileContainer, desc: tileDesc } = tile;
    const isVisibleLootBox = this._utils.getSelect(getHudContainer);
    const isVisibleDialog = this._utils.getSelect(getHudDialog);

    if (tileItem?.amount > 0) {
      this._inventoryPickService.pickItem(tileItem, y, x);
    } else if (tileContainer) {
      const containerItems = this._utils.getSelect(getContainerItems);
      if (!isVisibleLootBox) {
        this._containerService.getContainer(tileContainer.kind);
      } else if (containerItems.length > 0) {
        this._containerService.pickContainerItem(0);
      }
    } else if (
      characterFound?.state &&
      characterFound.state !== 'fight' &&
      isVisibleDialog
    ) {
      const options = this._utils.getSelect(getDialogOptions);

      if (options?.length > 0 && !options[0].disable) {
        this._dialogManagerService.chooseOption(options[0]);
      } else if (!options && this._dialogManagerService.isNextMsg()) {
        this._dialogManagerService.nextMsg();
      } else {
        this._dialogInit.endDialog();
      }
    } else if (characterFound?.state && characterFound.state !== 'fight') {
      this._playerDbService
        .updatePlayer()
        .pipe(take(1))
        .subscribe(() => {
          this._dialogInit.initDialog(player, characterFound);
        });
    } else if (tile.workshop) {
      this._workshopService.initWorkshop(tile.workshop);
    } else if (tile.harvestPoint) {
      const containerHud = this._utils.getSelect(getHudContainer);
      if (!containerHud) {
        this._harvestPointService.initHarvest(tile.harvestPoint);
      } else {
        this._containerService.pickContainerItem(0);
      }
    } else if (tileDesc) {
      this._notificationService.addNotification('desc', tileDesc);
    }
  }

  // Argument : ------
  // Résultat : Ferme toutes les fenêtres ouvertes
  private _closeAllHUD(): void {
    this._store.dispatch(HudDisplayActions.reset());
  }

  // Arguments : ------
  // Résultat : Supprime l'intervalle de tick des  inputs
  private _clearTimer(): void {
    clearInterval(this._intervalTickInput);
  }
}
