import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getPlayer } from '@t12/store/characters/selectors/characters.selectors';
import { DialogActions } from '@t12/store/dialog/actions/dialog.actions';
import { getDialogCharacter } from '@t12/store/dialog/selectors/dialog.selectors';
import { HudDisplayActions } from '@t12/store/hud-display/actions/hud-display.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { take } from 'rxjs';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { DialogDbService } from '../dialog-db/dialog-db.service';

@Injectable({
  providedIn: 'root',
})
export class DialogInitService {
  constructor(
    private readonly _characterService: CharacterManagerService,
    private readonly _dialogDb: DialogDbService,
    private readonly _timerService: TimersManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : ------
  // Résultat : Initialise le dialogue en mettant à jour ses variables
  public initDialog(player: Player, character: Monster | NPC): void {
    this._dialogDb
      .getDialog(character.dialogCode)
      .pipe(take(1))
      .subscribe((textsDialog) => {
        if (textsDialog.length === 0) return;

        this._prepareCharacter(player, character);
        this._store.dispatch(DialogActions.setDialog({ textsDialog }));
        this._store.dispatch(HudDisplayActions.showHud({ name: 'dialog' }));
      });
  }

  // Argument : Joueur, Personnage
  // Résultat : Initialise les paramètres du dialogue et tourne le NPC face au joueur
  private _prepareCharacter(player: Character, character: NPC | Monster): void {
    this._store.dispatch(DialogActions.setCharacterDialog({ character }));
    this._characterService.setFaceTo(player, character);

    if (!character.static) {
      this._timerService.stopTimerMoveByID(character.idCharacter);
    }
  }

  // Argument : ------
  // Résultat : Met fin à une conversation et restaure les variables par défaut
  public endDialog(): void {
    this._store.dispatch(HudDisplayActions.hideHud({ name: 'dialog' }));
    const character: NPC | Monster = this._utils.getSelect(getDialogCharacter);
    if (character) {
      const { idCharacter, originalLooking } = character;
      this._store.dispatch(
        CharactersActions.setLooking({ idCharacter, looking: originalLooking }),
      );

      if (!character.static && !character.aggressive) {
        this._characterService.createPatrolInterval(character.idCharacter);
      } else if (character.aggressive) {
        const player = this._utils.getSelect(getPlayer);
        this._characterService.createTurnFightInterval(player, character);
      }
    }

    this._store.dispatch(DialogActions.init());
  }
}
