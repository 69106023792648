import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Language } from '@t12/common/user/types/language.type';
import { TokenPayload } from '@t12/login/interfaces/userdetails';
import { UserState } from '../index';

export const UserActions = createActionGroup({
  source: 'USER',
  events: {
    Init: emptyProps(),
    'Change mail': props<{ email: string }>(),
    'Change music state': props<{ music: boolean }>(),
    'Change noises state': props<{ noises: boolean }>(),
    'Login user': props<{ token: TokenPayload }>(),
    'Login user success': props<{ user: UserState }>(),
    Logout: emptyProps(),
    'Register user': props<{ token: TokenPayload }>(),
    'Register user success': props<{ user: UserState }>(),
    'Register login user fail': props<{ error: HttpErrorResponse }>(),
    'Change language': props<{ language: Language }>(),
    'Load user': props<{ user: UserState }>(),
    'Login redirect page': props<{ email: string }>(),
  },
});
