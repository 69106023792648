import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { CharacterCreationManagerService } from '@t12/creation-player/services/character-creation-manager/character-creation-manager.service';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { catchError, map, switchMap, of, take } from 'rxjs';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  private _loginUser = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loginUser),
      switchMap(({ token }) =>
        this._authService.login$(token).pipe(
          map(() =>
            UserActions.loginUserSuccess({
              user: this._authService.getUserDetails(),
            }),
          ),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  private _loginUserSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loginUserSuccess),
      switchMap(({ user }) => {
        this._audioService.playBackgroundMusic('home');
        this._characterCreationService
          .getPlayerCharacters$()
          .pipe(take(1))
          .subscribe((characters: Player[]) => {
            if (characters.length !== 0) {
              this._router.navigateByUrl('/characters');
            } else {
              this._router.navigateByUrl('/characters/new');
            }
          });

        return of(UserActions.loginRedirectPage({ email: user.email }));
      }),
    ),
  );

  private _registerUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.registerUser),
      switchMap(({ token }) =>
        this._authService.register$(token).pipe(
          take(1),
          map(() =>
            UserActions.registerUserSuccess({
              user: this._authService.getUserDetails(),
            }),
          ),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
          take(1),
        ),
      ),
    ),
  );

  private _registerUserSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.registerUserSuccess),
      switchMap(({ user }) => {
        this._router.navigateByUrl('/characters/new');
        this._audioService.playBackgroundMusic('home');

        return of(UserActions.loginRedirectPage({ email: user.email }));
      }),
    ),
  );

  private _loadUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loadUser, UserActions.loginUserSuccess),
      switchMap(({ user }) => {
        this._translocoService.setActiveLang(user.language);

        return of(UserActions.loginRedirectPage({ email: user.email }));
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _authService: AuthenticationService,
    private readonly _characterCreationService: CharacterCreationManagerService,
    private readonly _router: Router,
    private readonly _translocoService: TranslocoService,
  ) {}
}
