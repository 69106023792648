import { createReducer, on } from '@ngrx/store';
import { HotkeyActions } from '../actions/hotkey.actions';
import { HotkeyState, initialHotkeyState } from '../index';

const keys = ['&', 'é', '"', "'", '('];

export const HotkeyReducer = createReducer(
  initialHotkeyState,

  on(HotkeyActions.init, () => initialHotkeyState),

  on(HotkeyActions.addHotkey, (hotkeyState: HotkeyState[], { hotkey }) => {
    const alreadyAdded = hotkeyState.some(
      (hotkeyItem) => hotkeyItem.entity.code === hotkey.entity.code,
    );
    if (hotkeyState.length >= keys.length || alreadyAdded) {
      return hotkeyState;
    }

    return [...hotkeyState, { ...hotkey, key: keys[hotkeyState.length] }];
  }),

  on(
    HotkeyActions.setHotkeyAt,
    (hotkeyState: HotkeyState[], { hotkey, index }) => [
      ...hotkeyState.slice(0, index),
      { ...hotkey, key: keys[index] },
      ...hotkeyState.slice(index + 1),
    ],
  ),

  on(HotkeyActions.removeHotkey, (hotkeyState: HotkeyState[], { code }) => {
    const updateHotkeyState: HotkeyState[] = hotkeyState.filter(
      (hotkey) => hotkey.entity.code !== code,
    );
    return updateHotkeyState.map((hotkey, index) => ({
      ...hotkey,
      key: keys[index],
    }));
  }),

  on(
    HotkeyActions.setHotkeys,
    (hotkeyState: HotkeyState[], { hotkeys }) => hotkeys,
  ),
);
