import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { getPlayerID } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utils: UtilsService,
  ) {}

  public initQuestPlayer(questCode: string, npcCode: string): Observable<any> {
    const playerId = this._utils.getSelect(getPlayerID);
    const body = { playerId, questCode, npcCode };

    return this._http.post(`${environment('quest/init').uri}`, body, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    });
  }

  public getQuestsPlayer(): Observable<QuestInfos[]> {
    const playerId = this._utils.getSelect(getPlayerID);

    return this._http.get(`${environment(`quest/player/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<QuestInfos[]>;
  }

  public validateQuest(questCode: string): Observable<QuestInfos[]> {
    const playerId = this._utils.getSelect(getPlayerID);

    return this._http.get(
      `${environment(`quest/player/${playerId}/validate/${questCode}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<QuestInfos[]>;
  }

  public abandonQuest(questCode: string): Observable<QuestInfos[]> {
    const playerId = this._utils.getSelect(getPlayerID);

    return this._http.get(
      `${environment(`quest/player/${playerId}/quit/${questCode}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<QuestInfos[]>;
  }
}
