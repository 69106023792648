import { createReducer, on } from '@ngrx/store';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';
import { shopState } from '@t12/store/shop';
import { ShopActions } from '../actions/shop.actions';

export const ShopReducer = createReducer(
  shopState,

  on(ShopActions.init, () => shopState),

  on(ShopActions.setShop, (shopState: Shop, { shop }) => {
    return shop;
  }),
);
