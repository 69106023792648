import { createReducer, on } from '@ngrx/store';
import { ChatState, initialChatState } from '@t12/store/chat';
import { ChatActions } from '../actions/chat.actions';

export const ChatReducer = createReducer(
  initialChatState,

  on(ChatActions.init, () => initialChatState),

  on(ChatActions.setTabChat, (chatState: ChatState, { tab }) => ({
    ...chatState,
    activeTab: tab,
  })),

  on(ChatActions.addChatLog, (chatState: ChatState, { tab, chatLog }) => ({
    ...chatState,
    [tab]: [...chatState[tab], chatLog],
  })),
);
