import { Injectable } from '@angular/core';
import { IHarvestPoint } from '@t12/common/job/interfaces/harvest-point.interface';
import { ResultHarvest } from '@t12/common/job/interfaces/result-harvest.interface';
import { ContainerUtilsService } from '@t12/container/services/container-utils/container-utils.service';
import { ContainerManagerService } from '@t12/container/services/container/container-manager.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { take } from 'rxjs';
import { HarvestDbService } from '../harvest-db/harvest-db.service';
import { JobManagerService } from '../job-manager/job-manager.service';

@Injectable({
  providedIn: 'root',
})
export class HarvestPointManagerService {
  constructor(
    private readonly _containerService: ContainerManagerService,
    private readonly _containerUtilsService: ContainerUtilsService,
    private readonly _harvestDb: HarvestDbService,
    private readonly _jobService: JobManagerService,
    private readonly _notificationService: NotificationManagerService,
  ) {}

  public initHarvest(harvestPoint: IHarvestPoint) {
    const { jobCode, requiredXp } = harvestPoint;
    if (!this._jobService.canInteractEntityJob(jobCode, requiredXp)) return;

    this._harvestDb
      .getHarvestRessources()
      .pipe(take(1))
      .subscribe((result: ResultHarvest) => {
        const { container, playerJobXp } = result;

        if (!container) {
          this._containerUtilsService.closeContainer();
          this._notificationService.addNotification(
            'error',
            'Ce point de récolte a expiré.',
          );
          return;
        }

        if (playerJobXp) {
          const job = this._jobService.getJob(jobCode);
          this._jobService.setXpToActiveJob(job, playerJobXp);
        }

        if (container.items.length === 0) {
          this._notificationService.addNotification(
            'error',
            'Récolte échouée.',
          );
        } else {
          this._containerService.initContainerHud(container);
        }
      });
  }
}
