import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { StatsCharacter } from '@t12/common/characters/interfaces/stats-character.interface';
import { Looking } from '@t12/common/characters/types/looking.type';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { PlayerJobs } from '@t12/common/job/interfaces/player-jobs.interface';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    Reset: emptyProps(),
    'Load player': emptyProps(),
    'Load player success': props<{ player: Player }>(),
    'Init player success': emptyProps(),
    'Load player failed': props<{ error: HttpErrorResponse }>(),
    'Add character': props<{ character: NPC | Monster }>(),
    'Set characters': props<{ characters: (NPC | Monster)[] }>(),
    'Set characters success': emptyProps(),
    'Reset characters': emptyProps(),
    'Set player track id': props<{ trackId: number }>(),
    'Add position x': props<{ idCharacter: number; x: number }>(),
    'Add position y': props<{ idCharacter: number; y: number }>(),
    'Add position x y': props<{ idCharacter: number; y: number; x: number }>(),
    'Set position x y': props<{ idCharacter: number; y: number; x: number }>(),
    'Add top': props<{ idCharacter: number; top: number }>(),
    'Add left': props<{ idCharacter: number; left: number }>(),
    'Set top': props<{ idCharacter: number; top: number }>(),
    'Set left': props<{ idCharacter: number; left: number }>(),
    'Set looking': props<{ idCharacter: number; looking: Looking }>(),
    'Add sprite x': props<{ idCharacter: number; spriteX: number }>(),
    'Add sprite y': props<{ idCharacter: number; spriteY: number }>(),
    'Set sprite x': props<{ idCharacter: number; spriteX: number }>(),
    'Set sprite Y': props<{ idCharacter: number; spriteY: number }>(),
    'Set can move': props<{ idCharacter: number; canMove: boolean }>(),
    'Toggle first step': props<{ idCharacter: number }>(),
    'Set world code': props<{ idCharacter: number; worldCode: string }>(),
    'Set health mana gold': props<{
      idCharacter: number;
      health: number;
      mana: number;
      gold: number;
    }>(),
    'Add health': props<{ idCharacter: number; health: number }>(),
    'Set health': props<{ idCharacter: number; health: number }>(),
    'Add mana': props<{ idCharacter: number; mana: number }>(),
    'Set mana': props<{ idCharacter: number; mana: number }>(),
    'Add stat': props<{
      idCharacter: number;
      stat: keyof StatsCharacter;
      amount: number;
    }>(),
    'Set stats': props<{ idCharacter: number; stats: StatsCharacter }>(),
    'Add xp': props<{ idCharacter: number; xp: number }>(),
    'Set xp': props<{ idCharacter: number; xp: number }>(),
    'Add lvl': props<{ idCharacter: number }>(),
    'Set lvl': props<{ idCharacter: number; lvl: number }>(),
    'Add gold': props<{ idCharacter: number; gold: number }>(),
    'Set gold': props<{ idCharacter: number; gold: number }>(),
    'Set state': props<{ idCharacter: number; state: 'fight' | 'passive' }>(),
    'Add item in inventory': props<{
      idCharacter: number;
      item: Item;
      index: number;
    }>(),
    'Remove item in inventory': props<{
      idCharacter: number;
      itemCode: string;
      amount: number;
    }>(),
    'Set inventory': props<{ idCharacter: number; inventory: Item[] }>(),
    'Set equipment': props<{
      idCharacter: number;
      equipment: Item;
      index: number;
    }>(),
    'Set equipments': props<{
      idCharacter: number;
      equipments: Item[];
    }>(),
    'Remove equipment': props<{ idCharacter: number; index: number }>(),
    'Set have quest': props<{ idCharacter: number; hasQuest: boolean }>(),
    'Set is done quest': props<{ idCharacter: number; isDone: boolean }>(),
    'Add new job': props<{
      idCharacter: number;
      job: PlayerJob;
    }>(),
    'Remove job': props<{
      idCharacter: number;
      job: PlayerJob;
    }>(),
    'Set jobs': props<{
      idCharacter: number;
      jobs: PlayerJobs;
    }>(),
    'Set active job xp': props<{
      idCharacter: number;
      kind: JobKind;
      index: number;
      xp: number;
    }>(),
  },
});
