import { createReducer, on } from '@ngrx/store';
import { sortItemsByTypeNameAmount } from '@t12/common/inventory/functions/sort-items/sortItemsByTypeNameAmount.function';
import { ContainerState, initialContainerState } from '@t12/store/container';
import { ContainerActions } from '@t12/store/container/actions/container.actions';

export const ContainerReducer = createReducer(
  initialContainerState,

  on(ContainerActions.init, () => ({
    ...initialContainerState,
  })),

  on(
    ContainerActions.setContainer,
    (containerState: ContainerState, { container }) => ({
      ...initialContainerState,
      ...container,
    }),
  ),

  on(
    ContainerActions.setItemsDisplayed,
    (containerState: ContainerState, { items }) => {
      const sortedItemsContainer = JSON.parse(JSON.stringify(items)).sort(
        sortItemsByTypeNameAmount,
      );

      return {
        ...containerState,
        displayedItems: sortedItemsContainer,
      };
    },
  ),

  on(
    ContainerActions.setCurrentPage,
    (containerState: ContainerState, { page }) => ({
      ...containerState,
      currentPage: page,
    }),
  ),

  on(
    ContainerActions.setMaxPage,
    (containerState: ContainerState, { maxPage }) => ({
      ...containerState,
      maxPage,
    }),
  ),

  on(
    ContainerActions.removeItem,
    (containerState: ContainerState, { itemCode }) => ({
      ...containerState,
      items: containerState.items.filter((item) => item.code !== itemCode),
    }),
  ),
);
