import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { tap } from 'rxjs';
import { QuestsManagerService } from '@t12/quest/services/quests-manager/quests-manager.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { DialogActions } from '../actions/dialog.actions';
import { getDialogOptions } from '../selectors/dialog.selectors';

@Injectable()
export class DialogEffects {
  private setDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DialogActions.setDialog),
        tap(() => {
          const options: OptionDialog[] =
            this._utils.getSelect(getDialogOptions);
          if (!options) return;

          options.forEach((option) => {
            if (option.validateQuest) {
              option.disable = !this._questService.isQuestFinished(
                option.validateQuest,
              );
            }
          });
          this._store.dispatch(DialogActions.setOptions({ options }));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _questService: QuestsManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}
}
