import { Injectable } from '@angular/core';
import { getPlayerInventory } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { BehaviorSubject } from 'rxjs';
import { ActiveItem } from '../../interfaces/active-item.interface';

@Injectable({
  providedIn: 'root',
})
export class InventoryManagerService {
  private _activeItem$: BehaviorSubject<ActiveItem> = new BehaviorSubject(null);

  constructor(private readonly _utils: UtilsService) {}

  // Arguments : ------
  // Résultat : Renvoie l'objet actif dans la sélection dans l'inventaire
  public getActiveItem(): BehaviorSubject<ActiveItem> {
    return this._activeItem$;
  }

  // Arguments : Index du slot inventaire
  // Résultat : Ajoute l'objet correspondant en tant qu'actif
  public setActiveItem(index: number): void {
    const inventory = this._utils.getSelect(getPlayerInventory);
    const isSameActiveItem = this._activeItem$.value?.index === index;

    if (
      !inventory[index].amount ||
      !inventory[index].code ||
      isSameActiveItem
    ) {
      this.removeActiveItem();
      return;
    }
    const { code, price, stats, type } = inventory[index];
    const activeItem: ActiveItem = {
      ...inventory[index],
      index,
      code,
      price: Math.floor(price * 0.5),
      displayedStats: stats ? Object.entries(inventory[index].stats) : null,
      type,
    };

    this._activeItem$.next(activeItem);
  }

  // Argument : ------
  // Résultat : Retire l'objet actif
  public removeActiveItem(): void {
    this._activeItem$.next(null);
  }
}
