import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { TabChat } from '@t12/store/chat';

export const ChatActions = createActionGroup({
  source: 'CHAT',
  events: {
    Init: emptyProps(),
    'Set tab chat': props<{ tab: TabChat }>(),
    'Add chat log': props<{ tab: TabChat; chatLog: ChatLog }>(),
  },
});
