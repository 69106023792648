import { Injectable } from '@angular/core';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { World } from '@t12/common/world/interfaces/world.interface';
import { take } from 'rxjs';
import { WorldDbService } from '../world-db/world-db.service';
import { WorldGeneratorService } from '../world-generator/world-generator.service';

@Injectable({
  providedIn: 'root',
})
export class WorldWarpService {
  constructor(
    private readonly _worldDbService: WorldDbService,
    private readonly _worldGeneratorService: WorldGeneratorService,
  ) {}

  public teleportTo(scroll?: string) {
    this._worldGeneratorService.setLoadingState(true);

    this._worldDbService
      .getNextWorld(scroll)
      .pipe(take(1))
      .subscribe(({ world, warp }: { world: World; warp: Warp }) => {
        this._worldGeneratorService.setWorld(world, warp);
      });
  }
}
