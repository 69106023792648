import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import {
  getActiveJobKind,
  getActiveJobIndex,
} from '@t12/store/job/selectors/job.selectors';

const selectRoot = createFeatureSelector<Character[]>('characters');

export const getCharacters = createSelector(
  selectRoot,
  (characters: Character[]) => characters,
);

export const getPlayer = createSelector(
  selectRoot,
  (characters: Character[]) => characters[0] as Player,
);

export const getPlayerID = createSelector(
  selectRoot,
  (characters: Character[]) => (characters[0] as Player)?.id,
);

export const getPlayerName = createSelector(
  selectRoot,
  (characters: Character[]) => characters[0]?.name,
);

export const getPlayerPosition = createSelector(
  selectRoot,
  (characters: Character[]) => characters[0]?.position,
);

export const getPlayerStats = createSelector(
  selectRoot,
  (characters: Character[]) => characters[0]?.stats,
);

export const getPlayerInventory = createSelector(
  selectRoot,
  (characters: Character[]) => (characters[0] as Player)?.inventory,
);

export const getPlayerWorldCode = createSelector(
  selectRoot,
  (characters: Character[]) => (characters[0] as Player)?.worldCode,
);

export const getPlayerGold = createSelector(
  selectRoot,
  (characters: Character[]) => (characters[0] as Player)?.gold,
);

export const getPlayerJobs = createSelector(
  selectRoot,
  (characters: Character[]) => (characters[0] as Player)?.jobs,
);

export const getActivePlayerJob = (utilsService) =>
  createSelector(selectRoot, (characters: Character[]) => {
    const player = characters[0] as Player;
    const kind = utilsService.getSelect(getActiveJobKind);
    const index = utilsService.getSelect(getActiveJobIndex);
    return player?.jobs[kind]?.[index];
  });
