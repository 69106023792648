import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { LANGUAGES } from '@t12/settings/constants/language.constant';
import { UserActions } from '@t12/store/user/actions/user.actions';
import { getUserLanguage } from '@t12/store/user/selectors/user.selectors';
import { DEFAULT_HEIGHT_FLAG } from '@t12/ui/constants/default-height-flag.constant';
import { UnSubscriberComponent } from '@t12/utils/component/unsubscriber.component';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Component({
  selector: 'app-language-select',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './language-select.component.html',
  styleUrl: './language-select.component.less',
})
export class LanguageSelectComponent extends UnSubscriberComponent {
  @Input() height: number = DEFAULT_HEIGHT_FLAG;
  @Input() update: boolean = false;
  public activeLangIndex =
    LANGUAGES.findIndex(
      (language) => language.code === this._utils.getSelect(getUserLanguage),
    ) || 0;

  public activeLang = this._store.select(getUserLanguage);

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _translocoService: TranslocoService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {
    super();
  }

  toggleLang() {
    this.activeLangIndex = this.activeLangIndex + 1;
    if (this.activeLangIndex > LANGUAGES.length - 1) this.activeLangIndex = 0;

    this._store.dispatch(
      UserActions.changeLanguage({
        language: LANGUAGES[this.activeLangIndex].code,
      }),
    );
    this._translocoService.setActiveLang(LANGUAGES[this.activeLangIndex].code);

    if (this.update)
      this._authService
        .updateUserDetails$({ language: LANGUAGES[this.activeLangIndex].code })
        .pipe(this.takeUntilDestroyed())
        .subscribe();
  }
}
