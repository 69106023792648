import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { EquipmentService } from '@t12/equipments/services/equipments/equipment.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { KnowledgeManagerService } from '@t12/jobs/services/knowledge-manager/knowledge-manager.service';
import { labelInfos } from '@t12/player/constants/player-infos';
import { PlayerManagerService } from '@t12/player/services/player-manager/player-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopManagerService } from '@t12/shop/services/shop/shop-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import {
  getPlayer,
  getPlayerInventory,
} from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldWarpService } from '@t12/world/services/world-warp/world-warp.service';
import { InventoryDbService } from '../inventory-db/inventory-db.service';
import { InventoryManagerService } from '../inventory-manager/inventory-manager.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryUseService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _equipmentService: EquipmentService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _inventoryManagerService: InventoryManagerService,
    private readonly _itemDepositService: ItemDepositService,
    private readonly _knowledgeService: KnowledgeManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerService: PlayerManagerService,
    private readonly _shopService: ShopManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
    private readonly _worldWarpService: WorldWarpService,
  ) {}

  // Argument : ------
  // Résultat : Tente d'utiliser un objet si l'option est possible
  public tryToUseItem(code: string, type: string): void {
    const playerInventory = this._utils.getSelect(getPlayerInventory);
    const itemIndex = playerInventory.findIndex(
      (itemInventory) =>
        itemInventory.code === code && itemInventory.type === type,
    );
    if (itemIndex === -1) {
      this._notificationService.addNotification(
        'error',
        'Vous ne possédez pas ça sur vous!',
      );
      return;
    }
    const item = playerInventory[itemIndex];
    const hasUse = !!item.use;

    if (item.stats && this._equipmentService.canEquipItem(item)) {
      this._equipmentService.addEquipment(Object.assign({}, item));
      this._inventoryManagerService.removeActiveItem();
    } else if (hasUse && this._canUseItem(item)) {
      this._useActiveItem(itemIndex);
    }
  }

  // Argument : Joueur, Objet utilisé
  // Résultat : Vérifie si l'objet est utilisable
  private _checkUseConditions(item: Item): boolean {
    const player = this._utils.getSelect(getPlayer);
    if (!player || !item?.use) {
      return false;
    }

    if (player.health <= 0) {
      this._notificationService.addNotification('error', 'Vous êtes mort...');
      return false;
    }

    if (item.lvl > player.lvl) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas le niveau pour faire ça!",
      );
      return false;
    }
    const hasInfos = !!item.use.infos;

    if (hasInfos) {
      const maxHealthP = maxHealth(player.stats.con);
      const maxManaP = maxMana(player.stats.int);
      const { infos } = item.use;
      const isMaxHealth =
        player.health === maxHealthP &&
        infos.length === 1 &&
        infos[0].stat === 'health';
      const isMaxMana =
        player.mana === maxManaP &&
        infos.length === 1 &&
        infos[0].stat === 'mana';
      const canHealOrMana =
        player.health < maxHealthP || player.mana < maxManaP;

      if (isMaxHealth || isMaxMana) {
        const errorMessage = isMaxHealth
          ? 'Votre vie est déjà au maximum!'
          : 'Votre mana est déjà au maximum!';
        this._notificationService.addNotification('error', errorMessage);
        return false;
      }

      if (!canHealOrMana && infos.length === 2) {
        this._notificationService.addNotification(
          'error',
          'Votre vie et mana sont déjà au maximum!',
        );
        return false;
      }
    }

    return true;
  }

  // Argument : Objet à utiliser
  // Résultat : Vérifie si l'objet est utilisable
  private _canUseItem(item: Item): boolean {
    if (!this._checkUseConditions(item)) return false;

    if (!!item.use?.infos) {
      const text = item.use.infos
        .map((useInfo) => `+${useInfo.value} ${labelInfos[useInfo.stat]}`)
        .join(' ');
      this._notificationService.addNotification('item', text, 3000, item.img);
    }

    return true;
  }

  // Argument : Objet à utiliser
  // Résultat : Utilise l'objet
  private _useActiveItem(index: number, amount = 1): void {
    const player = this._utils.getSelect(getPlayer);
    const { name: playerName, inventory } = player;
    const item = inventory[index];
    const { use: itemUse } = item;

    if (inventory[index].amount < amount) {
      return;
    }

    this._chatService.addChatLog(
      'action',
      playerName,
      `a utilisé l'objet "${item.name}".`,
      ChatLogKind.Bonus,
    );

    if (itemUse.infos?.length > 0) {
      this._soundUseItem(item.type);
      itemUse.infos.forEach((info) => {
        this._playerService.updateInfosPlayerCharacter(info.stat, info.value);
      });
      this._itemDepositService.removeItemFrom(
        ContainerKind.Inventory,
        item.code,
      );
      this._inventoryDbService.useItem(item.code);
    } else if (itemUse.warp) {
      this._shopService.closeShop();
      this._store.dispatch(
        CharactersActions.setLooking({ idCharacter: 0, looking: 'down' }),
      );
      this._itemDepositService.removeItemFrom(
        ContainerKind.Inventory,
        item.code,
      );
      this._worldWarpService.teleportTo(item.code);
    } else if (itemUse.learn) {
      this._knowledgeService.learnKnowledge(item);
    }

    if (item.amount - amount <= 0) {
      this._inventoryManagerService.removeActiveItem();
    }
  }

  // Argument : Type de l'objet
  // Résultat : Emet un son correspondant au contexte de l'objet utilisé
  private _soundUseItem(typeItem: string): void {
    if (typeItem === 'potion') {
      this._audioService.playSound(
        'miscs',
        `gulp_${Math.floor(Math.random() * 2)}`,
        'wav',
        0.35,
      );
    } else if (typeItem === 'food') {
      this._audioService.playSound(
        'miscs',
        `munch_${Math.floor(Math.random() * 4)}`,
        'wav',
        0.35,
      );
    }
  }
}
