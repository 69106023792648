import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Character } from '@t12/common/characters/interfaces/character.interface';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';

export const DialogActions = createActionGroup({
  source: 'DIALOG',
  events: {
    Init: emptyProps(),
    'Set dialog': props<{ textsDialog: TextDialog[] }>(),
    'Next message': emptyProps(),
    'Set character dialog': props<{ character: Character }>(),
    'Set options': props<{ options: OptionDialog[] }>(),
  },
});
