import { Injectable } from '@angular/core';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { NotificationManagerService } from '@t12/hud/services/notification/notification-manager.service';
import { getPlayer } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { InventoryDbService } from '../inventory-db/inventory-db.service';
import { InventoryUtilsService } from '../inventory-utils/inventory-utils.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryPickService {
  constructor(
    private readonly _chatService: ChatManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _inventoryUtilsService: InventoryUtilsService,
    private readonly _itemDepositService: ItemDepositService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : Objet à ramasser
  // Résultat : Vérifie si un objet est ramassable et renvoie l'index où il est rangeable
  public canPickItem(item: Item): number {
    const player = this._utils.getSelect(getPlayer);
    const { name, inventory } = player;

    for (let index = 0; index < inventory.length; index++) {
      const haveEnoughPlace = inventory[index].amount + item.amount <= item.max;

      if (inventory[index].code === item.code && haveEnoughPlace) {
        this._chatService.addChatLog(
          'action',
          name,
          `a ramassé l'objet "${item.name}"`,
          ChatLogKind.Bonus,
        );
        return index;
      }
    }

    const freeSlot = (itemInventory: Item): boolean =>
      itemInventory.amount === 0;
    const indexSlotFree = player.inventory.findIndex(freeSlot);
    if (indexSlotFree === -1) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas assez de place dans votre sac!",
      );
    } else {
      this._chatService.addChatLog(
        'action',
        name,
        `a ramassé l'objet "${item.name}"`,
        ChatLogKind.Bonus,
      );
    }
    return indexSlotFree;
  }

  // Argument : Objet à ramasser, index slot inventaire, coordonnées y et x de la tuile contenant l'objet
  // Résultat : Vérifie si un objet est ramassable, l'ajoute à l'inventaire et le retire de la map si coordonnées
  public pickItem(item: Item, y?: number, x?: number): void {
    const freeSlotsAmount =
      this._inventoryUtilsService.getFreeInventorySlotAmount(item);
    const pickedAmount = Math.min(freeSlotsAmount, item.amount);
    item.amount -= pickedAmount;

    if (pickedAmount === 0) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez pas assez de place dans votre sac!",
      );
      return;
    }

    this._itemDepositService.addItemInto(
      ContainerKind.Inventory,
      item,
      pickedAmount,
    );
    this._inventoryDbService.pickItem(item.code, pickedAmount);

    const noMoreItem = item.amount <= 0;
    if (y >= 0 && x >= 0 && noMoreItem) {
      this._worldService.removeEntity('item', y, x);
    }
  }
}
