import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultHarvest } from '@t12/common/job/interfaces/result-harvest.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { getPlayerID } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HarvestDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  public getHarvestRessources(): Observable<ResultHarvest> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(`${environment(`harvest/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<ResultHarvest>;
  }
}
