import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { QuestGoal } from '@t12/common/quest/interfaces/quest-goal.inferface';

export const QuestActions = createActionGroup({
  source: 'QUEST',
  events: {
    Init: emptyProps(),
    'Set quests': props<{ questsInfos: QuestInfos[] }>(),
    'Get quest': props<{ questCode: string }>(),
    'Add quest': props<{ questInfos: QuestInfos }>(),
    'Remove quest': props<{ questCode: string }>(),
    'Update goal': props<{ goal: QuestGoal; amount: number }>(),
  },
});
