import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { CharactersActions } from '@t12/store/characters/actions/characters.actions';
import { getCharacters } from '@t12/store/characters/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { WorldWarpService } from '@t12/world/services/world-warp/world-warp.service';
import { take } from 'rxjs';
import { TimersManagerService } from '../timers-bot/timers-manager.service';
import { SpriteAnimationsX } from '@t12/characters/enums/sprite-animations-x.enum';

@Injectable({
  providedIn: 'root',
})
export class CharacterMovementService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _worldService: WorldGeneratorService,
    private readonly _store: Store,
    private readonly _timerService: TimersManagerService,
    private readonly _utils: UtilsService,
    private readonly _worldWarpService: WorldWarpService,
  ) {}

  // Argument : Personnage à déplacer
  // Résultat : Fait avancer le personnage d'un pas dans la direction de son regard
  public moveForward(idCharacter: number): void {
    const character = this._utils
      .getSelect(getCharacters)
      .find((characterItem) => characterItem.idCharacter === idCharacter);
    if (!character) {
      return;
    }
    const direction = character.looking;
    this._moveAnimation(idCharacter);

    if (direction === 'right') {
      this._store.dispatch(
        CharactersActions.addLeft({ idCharacter, left: 32 }),
      );
      this._store.dispatch(
        CharactersActions.addPositionX({ idCharacter, x: 1 }),
      );
    } else if (direction === 'down') {
      this._store.dispatch(CharactersActions.addTop({ idCharacter, top: 32 }));
      this._store.dispatch(
        CharactersActions.addPositionY({ idCharacter, y: 1 }),
      );
    } else if (direction === 'left') {
      this._store.dispatch(
        CharactersActions.addLeft({ idCharacter, left: -32 }),
      );
      this._store.dispatch(
        CharactersActions.addPositionX({ idCharacter, x: -1 }),
      );
    } else if (direction === 'up') {
      this._store.dispatch(CharactersActions.addTop({ idCharacter, top: -32 }));
      this._store.dispatch(
        CharactersActions.addPositionY({ idCharacter, y: -1 }),
      );
    }
  }

  // Argument : Personne et direction
  // Résultat : Anime le personnage selon le sprite initial envoyé pour les déplacements
  private _moveAnimation(idCharacter: number): void {
    this._store.dispatch(
      CharactersActions.setCanMove({ idCharacter, canMove: false }),
    );
    // Pas
    setTimeout(() => {
      const character = this._utils
        .getSelect(getCharacters)
        .find((char) => char.idCharacter === idCharacter);
      const rand = Math.floor(Math.random());
      this._audioService.playSound('footsteps', `grass_${rand}`);
      this._store.dispatch(
        CharactersActions.setSpriteX({
          idCharacter,
          spriteX: character.firstStep
            ? SpriteAnimationsX.FIRST_STEP_X
            : SpriteAnimationsX.SECOND_STEP_X,
        }),
      );
    }, 138);

    // Immobilisation
    setTimeout(() => {
      this._store.dispatch(
        CharactersActions.setSpriteX({
          idCharacter,
          spriteX: SpriteAnimationsX.IDLE_STEP_X,
        }),
      );
      this._store.dispatch(
        CharactersActions.setCanMove({ idCharacter, canMove: true }),
      );
      const character = this._utils
        .getSelect(getCharacters)
        .find((char) => char.idCharacter === idCharacter);
      this._store.dispatch(CharactersActions.toggleFirstStep({ idCharacter }));

      if (!character?.isPlayer) {
        return;
      }
      const warp: Warp = this._worldService.getTile(
        character.position.y,
        character.position.x,
      )?.warp;
      if (warp) {
        this._timerService.stopAllTimersMove();
        this._timerService.stopAllTimersFight();
        this._playerDbService
          .updatePlayer()
          .pipe(take(1))
          .subscribe(() => {
            this._worldWarpService.teleportTo();
          });
      }
    }, 276);
  }
}
